export default {
  basename: '/#', // only at build time to set, like ///able-pro/react/default
  rtlLayout: false,
  theme: 'light',
  i18n: 'en',
  drawerOpen: false,
  // jwt: {
  //     secret: 'SECRET-KEY',
  //     timeout: '1 days',
  // },
  // firebase: {
  //     apiKey: 'AIzaSyD4NTMxu5a-E5aLF3bcka0wEj2SMQ-uRIg',
  //     authDomain: 'materially-36914.firebaseapp.com',
  //     projectId: 'materially-36914',
  //     storageBucket: 'materially-36914.appspot.com',
  //     messagingSenderId: '478479518287',
  //     appId: '1:478479518287:web:aa68a391320608737ff83a',
  //     measurementId: 'G-ZRET4JXFZH',
  // },
  // auth0: {
  //     client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
  //     domain: 'demo-localhost.us.auth0.com',
  // },
};

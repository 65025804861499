/* eslint-disable no-case-declarations */
import * as actionTypes from '../actionTypes';

export const initialState = {
  isFetching: false,
  devicesPeerUser: [],
  devices: [],
  devices_data: {
    total_devices_count: 0,
    ofline_devices_count: 0,
    onboarding_devices_count: 0,
  },
  selectedDevice: null, // the serial number of the selected device for alerts
  fetchTs: new Date().getTime(),
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.DASHBOARD_RETRIVE:
      return {
        ...state,
        isFetching: false,
        devicesPeerUser: payload.devicesPeerUser,
        devices: payload.devices,
        selectedDevice: payload.selectedDevice,
        devices_data: payload.devices_data,
      };
    case actionTypes.SET_SELECTED_DEVICE:
      return {
        ...state,
        selectedDevice: payload.selectedDevice,
      };
    case actionTypes.DASHBOARD_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
export default dashboardReducer;

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Fragment } from 'react';

function MinimalLayout(props) {
  const { children } = props;
  return <>{children}</>;
}

export default MinimalLayout;

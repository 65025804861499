/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { createStore, compose, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { throttle } from 'lodash';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './layout/App';
import reducer from './store/reducer';
import config from './config';
import './assets/scss/theme.scss';
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './store/localStorageStateSync';
import { fetchAll } from './store/actions';

// import './helpers/ensure-basename';
// import ReactDOM from 'react-dom';

const persistedState = loadState();
const loggerMiddleware = createLogger();
const composeEnhancer = (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose;
const store = createStore(
  reducer,
  persistedState,
  composeEnhancer(
    applyMiddleware(
      thunkMiddleware, // lets us dispatch() functions
      loggerMiddleware, // neat middleware that logs actions
    ),
  ),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

Sentry.init({
  dsn: 'https://00212bcefa3d45058d3b4ef79493d2d4@o4504809440608256.ingest.sentry.io/4504809442181120',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

store.subscribe(throttle(() => {
  const {
    // persons,
    // vpn,
    login,
  } = store.getState();
  saveState({
    // persons,
    // vpn,
    login,
  });
}, 100));

store.dispatch(fetchAll());
// store.dispatch(fetchVpnClientStatusList());
// store.dispatch(fetchVpnClientToIpList());

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <CompatRouter>
        <App />
      </CompatRouter>
    </BrowserRouter>
  </Provider>,
  // document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

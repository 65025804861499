export function getNetworkStaticRoutes() {
  return fetch('/mtc/api/v1/network/static/route/all')
    .then((res) => res.json())
    // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

export function addStaticRouteViaVpn(dest, mask, gw) {
  return fetch('/mtc/api/v1/network/static/route/vpn', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dest,
      mask,
      gw,
    }),
  })
    .then((res) => res.json())
    // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

export function deleteStaticRouteViaVpn(dest, mask, gw) {
  return fetch('/mtc/api/v1/network/static/route/vpn', {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dest,
      mask,
      gw,
    }),
  })
    .then((res) => res.json())
    // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box, Grid, makeStyles, IconButton, Hidden,
} from '@material-ui/core';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

import SearchSection from './SearchSection';
import Customization from './Customization';
import ProfileSection from './ProfileSection';
import RefreshSection from './RefreshSection';

import NotificationSection from './NotificationSection';
import DownloadSection from './DownloadSection';
import MobileSection from './MobileSection';

import logo from '../../../assets/images/luceor-logo-hori-inversed2.png';
import { drawerWidth } from '../../../store/constant';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1.25),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
}));

function Header(props) {
  const { drawerToggle } = props;
  const classes = useStyles();

  return (
    <>
      <Box width={drawerWidth}>
        <Grid container justify="space-between" alignItems="center">
          <Hidden smDown>
            <Grid item>
              <Box mt={0.5}>
                <img height={25} src={logo} alt="Logo" />
              </Box>
            </Grid>
          </Hidden>
          <Grid item>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
            >
              <MenuTwoToneIcon className={classes.menuIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.grow} />

      {/* <SearchSection theme="light" /> */}
      <Customization />
      <RefreshSection />
      {/* <NotificationSection /> */}
      <ProfileSection />
      {/* <MobileSection /> */}
      {/* <DownloadSection /> */}
    </>
  );
}

export default Header;

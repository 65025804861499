import React, { lazy, Suspense } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { CompatRoute } from 'react-router-dom-v5-compat';

import { AnimatePresence } from 'framer-motion';

import Loader from './component/Loader/Loader';
import { AuthProvider } from './hooks/useAuth';
import MainLayout from './layout/MainLayout';
import MinimalLayout from './layout/MinimalLayout';
import NavMotion from './layout/NavMotion';
// import FinancialIncomes from './views/FinancialIncomes';

// const AuthLogin = lazy(() => import('./views/Login'));
const AuthLogin2 = lazy(() => import('./views/Login2'));
const ForgotPassword = lazy(() => import('./views/ForgotPassword'));
const PasswordReset = lazy(() => import('./views/ResetPassword'));

// const Price = lazy(() => import('./views/Application/Price/Price2'));

// const DashboardDefault = lazy(() => import('./views/Dashboard/Default'));

// const Home = lazy(() => import('./views/Home'));

// const RtlLayout = lazy(() => import('./views/RtlLayout'));

// const TableBasic = lazy(() => import('./views/Tables/TableBasic'));

// const UtilsIcons = lazy(() => import('./views/Utils/Icons'));
// const UtilsTypography = lazy(() => import('./views/Utils/Typography'));

const MultiLanguage = lazy(() => import('./views/MultiLanguage'));
const VpnView = lazy(() => import('./views/Vpn'));
const NetworkView = lazy(() => import('./views/StaticNetwork'));
const Users = lazy(() => import('./views/Users'));
// devices
const Devices = lazy(() => import('./views/Devices'));
// clients
const ClientsList = lazy(() => import('./views/Clients/List'));
const ClientNetworksList = lazy(() => import('./views/ClientNetworks/List'));
const ClientNetworkDetails = lazy(() => import('./views/ClientNetworks/Details'));

const ClientsInfo = lazy(() => import('./views/Clients/Info'));
const ClientDashboard = lazy(() => import('./views/Dashboard/ClientDashboard'));

const VpnCertList = lazy(() => import('./views/VPN2'));
const VpnStatus = lazy(() => import('./views/VpnStatus'));
const NetworkView2 = lazy(() => import('./views/StaticNetwork2'));
const VpnDashboard = lazy(() => import('./views/Dashboard/VpnDashboard'));

// const PeoplesView = lazy(() => import('./views/People'));
// const SubjectsView = lazy(() => import('./views/Subjects'));
// const SettingsView = lazy(() => import('./views/Settings'));
// const CoursesView = lazy(() => import('./views/Courses'));
// const ClassesView = lazy(() => import('./views/Classes'));
// const IncomesView = lazy(() => import('./views/FinancialIncomes'));
// const statementOfGradesView = lazy(() => import('./views/StatementOfGrades'));
// const associationConfigView = lazy(() => import('./views/AssociationConfig'));

const isAuthenticated = () => {
  // console.log(localStorage.getItem('logged'));
  // return true;

  if (localStorage.getItem('logged') === 'true') {
    return true;
  }
  return false;

  // if ('email' in localStorage) {
  //   return true;
  // }
  // return false;
};

function UnauthenticatedRoute({ component: Component, logged, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        !isAuthenticated()
          ? <Component {...props} />
          : <Redirect to="/vpnStatus" />
      )}
    />
  );
}

function AuthenticatedRoute({ component: Component, logged, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated()
          ? <Component {...props} />
          : <Redirect to="/login" />
      )}
    />
  );
}

function Routes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Suspense fallback={<Loader />}>
        <AuthProvider>
          <Switch>
            {/* <Route path="/login" name="Login" component={AuthLogin} logged /> */}
            {/* <Route path="/login" component={AuthLogin} /> */}
            <Route path="/login" component={AuthLogin2} />
            <Route path="/forgot/password" component={ForgotPassword} />
            <Route path="/reset-password" component={PasswordReset} />
            <Redirect exact from="/" to="/vpn-status" />
            <Route path={[]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <NavMotion />
                </Switch>
              </MinimalLayout>
            </Route>
            <Route
              path={[
                // '/register',
                // '/application/register',
                // '/forgot-password',
                // '/application/forgot-password',
                // '/application/login',
                // '/pages/error/error1',
                // '/pages/error/error2',
                '/home',
                '/vpn',
                '/vpn-status',
                '/network2',
                '/cert',
                '/network',
                '/networks',
                '/network-details',
                '/dashboard/vpn',
                '/dashboard2',
                '/users',
                '/devices',
                '/clients',
                // '/login',
                // '/login2',
                // '/login3',
                '/sample-page',
                '/multi-language',
                '/client/profile',
              ]}
            >
              <MainLayout>
                <Switch location={location} key={location.pathname}>
                  <NavMotion>
                    {/* <Route path="/home" component={DashboardDefault} />
                    <Route path="/system" component={Home} /> */}
                    {/* <Route exact path="/dashboard" component={DashboardDefault} /> */}
                    <Route path="/dashboard/vpn" component={VpnDashboard} />
                    <CompatRoute path="/vpn" component={VpnView} />
                    <CompatRoute path="/vpn-status" component={VpnStatus} />
                    <CompatRoute path="/network" component={NetworkView2} />
                    <CompatRoute path="/multi-language" component={MultiLanguage} />
                    <CompatRoute path="/cert" component={VpnCertList} />
                    <CompatRoute path="/users" component={Users} />
                    <CompatRoute path="/devices" component={Devices} />
                    <CompatRoute path="/clients" component={ClientsList} />
                    <CompatRoute path="/client/profile" component={ClientsInfo} />
                    <CompatRoute path="/networks" component={ClientNetworksList} />
                    <CompatRoute path="/network-details" component={ClientNetworkDetails} />
                    <CompatRoute path="/dashboard2" component={ClientDashboard} />
                    {/* <Route path="/network2" component={NetworkView2} /> */}
                    {/* <Route path="/rtl" component={RtlLayout} /> */}
                  </NavMotion>
                </Switch>
              </MainLayout>
            </Route>
          </Switch>
        </AuthProvider>
      </Suspense>
    </AnimatePresence>
  );
}

export default Routes;

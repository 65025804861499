/* eslint-disable no-case-declarations */
import * as actionTypes from '../actionTypes';

export const initialState = {
  isFetching: false,
  clientsList: [],
  fetchTs: new Date().getTime(),
};

const clientsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CLIENTS_REQUEST_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.CLIENTS_RECEIVE_LIST:
      return {
        ...state,
        isFetching: false,
        clientsList: payload.clientsList,
      };
    case actionTypes.CLIENT_ADD:
      return {
        ...state,
        clientsList: [...state.clientsList, payload.newClient],
      };
    case actionTypes.CLIENT_UPDATE:
      const updatedClientIndex = state.clientsList.findIndex((client) => client.idClient === payload.updatedClient.idClient);
      const updatedClientsList = [...state.clientsList];
      updatedClientsList[updatedClientIndex] = payload.updatedClient;
      return {
        ...state,
        clientsList: updatedClientsList,
      };
    case actionTypes.CLIENT_REMOVE:
      return {
        ...state,
        clientsList: state.clientsList.filter((client) => client.idClient !== payload.clientid),
      };
    case actionTypes.CLIENTS_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
export default clientsReducer;

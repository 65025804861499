import shortid from 'shortid';
import * as actionTypes from '../actionTypes';
import { idToParamMapBuilderFnc, idToObjMapBuilderFnc } from '../../helpers/functions';

const vpnCertFileDescription = {
  name: 'dont_revoke_cert_fethi',
  size: '2.72 KB',
  isFile: true,
  modified: '2023-01-24T09:17:46.473Z',
  created: '2023-01-24T09:17:46.473Z',
  type: '',
};

const vpnStatus = {
  data: {
    clientList: [{
      commonName: 'fedi1',
      realAddress: '197.21.177.71:7128',
      receivedBytes: '1082850',
      sentBytes: '10405338',
      connectedSince: 'Mon Mar 13 10:31:08 2023',
    }],
    routingTable: [{
      virtualAddress: '2e:b9:64:d2:47:b4',
      commonName: 'fedi1',
      lastRef: 'Mon Mar 13 10:31:11 2023',
    }],
  },
  error: false,
  message: 'success',
};
// const names = ['A', 'B'];
// const surnames = ['C', 'D'];
// const memberTypes = ['student', 'parent', 'teacher'];
// const genders = [1, 2, 3];
// const ranks = [14.5, 15, 10, 12.33, 17.5, 18.2];
// const coeffs = [1, 2, 3, 4];

export const initialState = {
  isFetching: false,
  isFetchingVpnCert: false,
  isFetchingVpnStatus: false,
  isFetchingVpnIp: false,
  isFetchingPki: false,
  vpnCertList: [],
  vpnClientStatusList: [],
  vpnClientIpList: [],
  pkiIndexList: [],
  fetchTs: new Date().getTime(),
  // vpnCertList: [vpnCertFileDescription],
  // vpnNetworkInfo: vpnStatus,
  // list: new Array(40).fill(0).map((key, index) => ({
  //   id: Math.round(Math.random() * 9999),
  // })),
};

// initialState.idPersonToObjMap = idToObjMapBuilderFnc(initialState.list, 'idPerson');
// initialState.idPersonToNameMap = idToParamMapBuilderFnc(initialState.list, 'idPerson', 'name');
// initialState.idPersonToSurnameMap = idToParamMapBuilderFnc(initialState.list, 'idPerson', 'surname');

// REQUEST_VPN_CERT_LIST,
// RECEIVE_VPN_CERT_LIST,
// REQUEST_VPN_STATUS_LIST,
// RECEIVE_VPN_STATUS_LIST,
// RECEIVE_VPN_CLIENT_TO_IP_LIST,
// REQUEST_VPN_CLIENT_TO_IP_LIST,
const vpnReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.REQUEST_VPN_CERT_LIST:
      return {
        ...state,
        isFetching: true,
        isFetchingVpnCert: true,
      };
    case actionTypes.REQUEST_PKI_INDEX_LIST:
      return {
        ...state,
        isFetching: true,
        isFetchingPki: true,
      };
    case actionTypes.REQUEST_VPN_STATUS_LIST:
      return {
        ...state,
        isFetching: true,
        isFetchingVpnStatus: true,
      };
    case actionTypes.REQUEST_VPN_CLIENT_TO_IP_LIST:
      return {
        ...state,
        isFetching: true,
        isFetchingVpnIp: true,
      };
    case actionTypes.RECEIVE_VPN_CERT_LIST:
      return {
        ...state,
        isFetching: false,
        isFetchingVpnCert: false,
        vpnCertList: action.payload.vpnCertList,
        vpnNetworkInfo: action.payload.vpnCertList,
      };
    case actionTypes.RECEIVE_PKI_INDEX_LIST:
      return {
        ...state,
        isFetching: false,
        isFetchingPki: false,
        pkiIndexList: action.payload.pkiIndexList,
      };
    case actionTypes.RECEIVE_VPN_STATUS_LIST:
      return {
        ...state,
        isFetching: false,
        isFetchingVpnStatus: false,
        vpnStatus: payload.vpnStatus,
        vpnClientStatusList: payload.vpnClientStatusList,
        // vpnCertList: action.payload.vpnCertList,
        // vpnNetworkInfo: action.payload.vpnCertList,
        // vpnClientStatusList: [],
        // vpnClientIpList: [],
        // list: action.payload.list,
        // idPersonToObjMap: idToObjMapBuilderFnc(action.payload.list, 'idPerson'),
        // idPersonToNameMap: idToParamMapBuilderFnc(action.payload.list, 'idPerson', 'name'),
        // idPersonToSurnameMap: idToParamMapBuilderFnc(action.payload.list, 'idPerson', 'surname'),
      };
    case actionTypes.RECEIVE_VPN_CLIENT_TO_IP_LIST: {
      // const { data: vpnClientIpList } = payload;
      // const { vpnStatus, vpnClientStatusList, vpnCertList } = state;
      // vpnCertList.forEach((i) => {
      //   if (vpnClientIpList[i.name]) {
      //     const clientStateIndex = vpnClientStatusList.findIndex((e) => e.name === i.name);
      //     if (clientStateIndex === -1) {
      //       vpnClientStatusList.push({
      //         commonName: i.name,
      //         connectedSince: '-',
      //         lastRef: '-',
      //         name: i.name,
      //         realAddress: '-',
      //         receivedBytes: '-',
      //         remotePort: '-',
      //         sentBytes: '-',
      //         virtualAddress: '-',
      //         vpnLocalAddress: vpnClientIpList[i.name],
      //       });
      //     } else {
      //       vpnClientStatusList[clientStateIndex].vpnLocalAddress = vpnClientIpList[i.name];
      //     }
      //   }
      // });
      return {
        ...state,
        isFetching: false,
        isFetchingVpnIp: false,
        vpnClientIpList: payload.data,
        // vpnClientStatusList: [...state.vpnClientStatusList],
        // vpnClientStatusList: state
        // vpnClientStatusList: payload.vpnClientStatusList,
        // vpnCertList: action.payload.vpnCertList,
        // vpnNetworkInfo: action.payload.vpnCertList,
        // vpnClientStatusList: [],
        // vpnClientIpList: [],
      };
    }

    default:
      return state;
  }
};

export default vpnReducer;

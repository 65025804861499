/* eslint-disable indent */
/* eslint-disable arrow-body-style */

/* eslint-disable implicit-arrow-linebreak */
import {
  getVpnClientToIpList,
  getCertificateList,
  getVpnClientStatus,
  getPkiIndex,
} from '../api/vpnFetcher';

import { getNetworkStaticRoutes } from '../api/networkFetcher';
import {
  getUsersByClientId, createUser, deleteUserById, updateUserById, updateUserFieldById,
} from '../api/usersFetcher';
import {
  getAllDevices, createDevice, updateDeviceById, deleteDeviceById, updateDeviceFieldById,
} from '../api/devices';
import {
  getAllClients, createClient, updateClientById, deleteClientById, updateClientFieldById, getClientDashboardData,
} from '../api/clients';
import {
  createNetwork, deleteNetworkById, getAllNetworks, getCleintNetworkVpnStatus, updateNetworkById,
} from '../api/networks';
import {
  SET_VPN_CLIENT_LIST,
  VPN_CLIENT_SERVER_VPN_STATUS_REQUEST_LIST,
  VPN_CLIENT_SERVER_VPN_STATUS_RECEIVE_LIST,
  VPN_CLIENT_SERVER_VPN_STATUS_CLEAR_DATA,
  REQUEST_VPN_CLIENT_LIST,
  RECEIVE_VPN_CLIENT_LIST,
  REQUEST_VPN_CERT_LIST,
  RECEIVE_VPN_CERT_LIST,
  REQUEST_PKI_INDEX_LIST,
  RECEIVE_PKI_INDEX_LIST,
  REQUEST_VPN_STATUS_LIST,
  RECEIVE_VPN_STATUS_LIST,
  RECEIVE_VPN_CLIENT_TO_IP_LIST,
  REQUEST_VPN_CLIENT_TO_IP_LIST,
  SET_STATIC_NETWORK_LIST,
  REQUEST_STATIC_NETWORK_LIST,
  RECEIVE_STATIC_NETWORK_LIST,
  SET_USER_INFO_STORE_ITEMS,
  REQUEST_USER_INFO,
  RECEIVE_USER_INFO,
  SET_USER_CONFIG,
  SET_NOTIF,
  RECEIVE_MORE_NOTIF,
  REQUEST_NOTIF,
  INVALIDATE_NOTIF,
  USERS_REQUEST_LIST,
  USERS_RECEIVE_LIST,
  DEVICES_REQUEST_LIST,
  DEVICES_RECEIVE_LIST,
  DEVICE_ADD,
  DEVICE_UPDATE,
  DEVICE_REMOVE,
  DEVICES_CLEAR_DATA,
  DEVICE_UPLOAD_CONFIG_FILE,
  CLIENTS_REQUEST_LIST,
  CLIENTS_RECEIVE_LIST,
  CLIENT_ADD,
  CLIENT_UPDATE,
  CLIENT_REMOVE,
  CLIENTS_CLEAR_DATA,
  USER_ADD,
  USER_UPDATE,
  USER_REMOVE,
  USERS_CLEAR_DATA,
  DASHBOARD_CLEAR_DATA,
  DASHBOARD_REQUEST,
  DASHBOARD_RETRIVE,
  SET_SELECTED_DEVICE,
  CLIENT_NETWORK_REQUEST,
  CLIENT_NETWORK_RETRIVE,
  CLIENT_NETWORK_CLEAR_DATA,
  SET_DEFAULT_CLIENT_NETWORK,
  CLIENT_NETWORK_ADD,
  CLIENT_NETWORK_REMOVE,
  CLIENT_NETWORK_UPDATE,
  SET_SELECTED_CLIENT_NETWORK,
  SET_LOGINDATA,
  CLEAR_LOGINDATA,
  DEVICE_UPDATE_FIELD_BY_ID,
} from './actionTypes';

// export const MENU_OPEN = '@customization/MENU_OPEN';
// export const THEME_LOCALE = '@customization/THEME_LOCALE';
// export const THEME_RTL = '@customization/THEME_RTL';
// export const THEME_TYPE = '@customization/THEME_TYPE';

const updateIt = 2000;

const addYearsToDate = (date, nbYears) => {
  date.setFullYear(date.getFullYear() + nbYears);
  return date;
};

export const setUserConfig = (userConfig) => ({
  type: SET_USER_CONFIG,
  payload: userConfig,
});

export const setNotif = (notif) => ({
  type: SET_NOTIF,
  payload: notif,
});

export const setVpnClientList = (vpnClientList) => ({
  type: SET_VPN_CLIENT_LIST,
  payload: vpnClientList,
});

export const invalidateNotif = (notif) => ({
  type: INVALIDATE_NOTIF,
  payload: notif,
});

const requestNotif = () => ({
  type: REQUEST_NOTIF,
});

const receiveMoreNotif = ({
  error = true,
  data = [],
  message = '',
}, nextPage) => ({
  type: RECEIVE_MORE_NOTIF,
  payload: {
    nextPage,
    error,
    message,
    notifList: data,
  },
});

export const fetchMoreNotif = () => (dispatch, getState) => { // loadMore notif
  const {
    nextPage = 0, pageSize = 15,
  } = getState().notification;
  const {
    isInfoNotif,
    isSuccessNotif,
    isWarningNotif,
    isErrorNotif,
  } = getState().userConfig;
  const notifLvl = [isInfoNotif, isSuccessNotif, isWarningNotif, isErrorNotif]
    .map((i, index) => i && (index + 1))
    .filter((i) => i).join('');
  dispatch(requestNotif(nextPage));
  // return getNotifByPageLoad(pageSize, nextPage, 1, notifLvl)
  //   .then((res = {}) => dispatch(receiveMoreNotif(res, nextPage)));
};

function shouldReadNotif(notif) {
  if (!notif || notif.isReadTs) {
    return false;
  }
  return true;
}

export const fetchNotifCount = () => (dispatch, getState) => {
  const {
    isInfoNotif,
    isSuccessNotif,
    isWarningNotif,
    isErrorNotif,
  } = getState().userConfig;
  const notifLvl = [isInfoNotif, isSuccessNotif, isWarningNotif, isErrorNotif]
    .map((i, index) => i && (index + 1))
    .filter((i) => i).join('');
  // return getUnreadNotifCount(notifLvl)
  //   .then((res) => {
  //     if (res && res.data) {
  //       const { nbUnreadNotif: unreadNotifCount = 0, nbNotif: notifCount = 0 } = res.data;
  //       return dispatch(setNotif({ unreadNotifCount, notifCount }));
  //     }
  //     return false;
  //   });
};

export const postReadNotifById = (idNotif) => (dispatch, getState) => {
  const {
    notifList,
    unreadNotifCount,
  } = getState().notification;
  const notifIndex = notifList.findIndex((item) => item.idRec === idNotif);
  const notif = {
    ...notifList[notifIndex],
  };
  if (shouldReadNotif(notif)) {
    notif.isReadTs = `${new Date().getTime()}`;
    const newNotifList = [...notifList];
    newNotifList.splice(notifIndex, 1, notif);
    // dispatch(setNotif({ notifList: newNotifList, unreadNotifCount: unreadNotifCount - 1 }));
    // return setIsReadNotif({ idRecs: [idNotif] })
    //   .then((res) => {
    //     if (res && res.error) {
    //       dispatch(setNotif({ notifList: newNotifList, unreadNotifCount }));
    //     }
    //   }).catch(() => dispatch(setNotif({ notifList, unreadNotifCount })));
  }
  return false;
};

/**
 * VPN ACTIONS Client List
 */

const requestVpnClientList = () => ({
  type: REQUEST_VPN_CLIENT_LIST,
});

const receiveVpnClientList = ({
  error = true,
  data = [],
  message = '',
}) => {
  const vpnNetworkInfo = {};
  const vpnCertList = data.map((i, d) => {
    const { name: certName, created, modified } = i;
    const id = `LUC30R_VPN_${(certName[0] || 'V')}${(certName[certName.length] || 'N')}_${new Date(created).getTime() % 1000}`;
    const certInfo = {
      ...i,
      id,
      created: (new Date(created)).toLocaleString(),
      modified: (new Date(modified)).toLocaleString(),
      expiration: addYearsToDate(new Date(i.created), 2).toLocaleString(),
      realAddress: '-',
      remotePort: '-',
      connectedSince: '-',
      receivedBytes: '-',
      sentBytes: '-',
      virtualAddress: '-',
      lastRef: '-',
    };
    vpnNetworkInfo[certName] = certInfo;
    return certInfo;
  });

  return {
    type: RECEIVE_VPN_CLIENT_LIST,
    payload: {
      error,
      message,
      vpnCertList,
      vpnNetworkInfo,
      // cowDates,
      // idCowToIdDiet,
      // idCowToNameMap,
      // cowIdGroupList,
      // idCowToIdGroups,
      // cowIndexToIdDietList,
      // shouldDisplayPrevDay,
    },
  };
};

export const fetchVpnClientList = () => (dispatch) => { // loadMore notif
  dispatch(requestVpnClientList());
  return getCertificateList()
    .then((res = {}) => dispatch(receiveVpnClientList(res)));
};

/**
 * VPN ACTIONS Certificate List
 */

const requestVpnCertList = () => ({
  type: REQUEST_VPN_CERT_LIST,
});

const receiveVpnCertList = ({
  error = true,
  data = [],
  message = '',
}) => {
  const vpnNetworkInfo = {};
  const vpnCertList = data.map((i, d) => {
    const { name: certName, created, modified } = i;
    const id = `LUC30R_VPN_${d}_${(certName[0] || 'V')}${(certName[certName.length - 1] || 'N')}_${new Date(created).getTime() % 1000}`;
    const certInfo = {
      ...i,
      id,
      cn: i?.name.replace('.p12', ''),
      created: (new Date(created)).toLocaleString(),
      modified: (new Date(modified)).toLocaleString(),
      expiration: addYearsToDate(new Date(i.created), 10).toLocaleString(),
      realAddress: '-',
      remotePort: '-',
      connectedSince: '-',
      receivedBytes: '-',
      sentBytes: '-',
      virtualAddress: '-',
      lastRef: '-',
    };
    vpnNetworkInfo[certName] = certInfo;
    return certInfo;
  });
  // await getVpnStatus().then(({ data: data2 = { clientList: [], routingTable: [] } }) => {
  //   const { clientList, routingTable } = data2;
  //   clientList.forEach((element) => {
  //     const certInfo = processedData.find(({ name }) => name === element.commonName);
  //     if (certInfo && certInfo.name && element && element.realAddress) {
  //       certInfo.realAddress = element.realAddress.split(':')[0];
  //       certInfo.remotePort = element.realAddress.split(':')[1];
  //       certInfo.connectedSince = element.connectedSince;
  //       certInfo.receivedBytes = element.receivedBytes;
  //       certInfo.sentBytes = element.sentBytes;
  //     }
  //   });
  //   routingTable.forEach((element) => {
  //     const certInfo = processedData.find(({ name }) => name === element.commonName);
  //     if (certInfo && certInfo.virtualAddress) {
  //       certInfo.virtualAddress = element.virtualAddress;
  //       certInfo.lastRef = element.lastRef;
  //     }
  //   });
  //   // setCertList(processedData);
  //   // setTableData(processedData);
  // });
  return {
    type: RECEIVE_VPN_CERT_LIST,
    payload: {
      error,
      message,
      vpnCertList,
      vpnNetworkInfo,
    },
  };
};

export const fetchVpnCertList = (idNetwork) => (dispatch) => { // loadMore notif
  dispatch(requestVpnCertList());
  return getCertificateList(idNetwork)
    .then((res = {}) => dispatch(receiveVpnCertList(res)));
};

/**
 * Pki Index ACTIONS
 */

const requestPkiIndexList = () => ({
  type: REQUEST_PKI_INDEX_LIST,
});

const receivePkiIndexList = ({
  error = true,
  data = [],
  message = '',
}) =>
// const vpnNetworkInfo = {};
// const vpnCertList = data.map((i, d) => {
//   const { name: certName, created, modified } = i;
//   const id = `LUC30R_VPN_${d}_${(certName[0] || 'V')}${(certName[certName.length - 1] || 'N')}_${new Date(created).getTime() % 1000}`;
//   const certInfo = {
//     ...i,
//     id,
//     cn: i?.name.replace('.p12', ''),
//     created: (new Date(created)).toLocaleString(),
//     modified: (new Date(modified)).toLocaleString(),
//     expiration: addYearsToDate(new Date(i.created), 10).toLocaleString(),
//     realAddress: '-',
//     remotePort: '-',
//     connectedSince: '-',
//     receivedBytes: '-',
//     sentBytes: '-',
//     virtualAddress: '-',
//     lastRef: '-',
//   };
//   vpnNetworkInfo[certName] = certInfo;
//   return certInfo;
// });
({
  type: RECEIVE_PKI_INDEX_LIST,
  payload: {
    error,
    message,
    pkiIndexList: data,
    // vpnCertList,
    // vpnNetworkInfo,
  },
});
export const fetchPkiIndexList = (idNetwork) => (dispatch) => { // loadMore notif
  dispatch(requestPkiIndexList());
  return getPkiIndex(idNetwork)
    .then((res = {}) => dispatch(receivePkiIndexList(res)));
};

/**
 * VPN ACTIONS Client Status List
 */

const requestVpnClientToIpList = () => ({
  type: REQUEST_VPN_CLIENT_TO_IP_LIST,
});

const receiveVpnClientToIpList = ({
  error = true,
  data = {},
  message = '',
}) => {
  const vpnClient = {};
  // let  = [];
  // const { clientList = [], routingTable = [] } = data;
  // // const vpnStatus = {};
  // const vpnClientStatusList = clientList.map((i) => {
  //   const { commonName, realAddress } = i;
  //   const routingInfo = routingTable.find((e) => e.commonName === commonName) || {};
  //   const singleVpnInfo = {
  //     ...i,
  //     name: commonName,
  //     realAddress: realAddress.split(':')[0],
  //     remotePort: realAddress.split(':')[1],
  //     ...routingInfo,
  //   };
  //   vpnStatus[commonName] = singleVpnInfo;
  //   return singleVpnInfo;
  // });
  return {
    type: RECEIVE_VPN_CLIENT_TO_IP_LIST,
    payload: {
      error,
      message,
      data,
      // vpnStatus,
      // vpnClientStatusList,
      // vpnCertList,
      // vpnNetworkInfo,
    },
  };
};

export const fetchVpnClientToIpList = (idNetwork) => (dispatch) => { // loadMore notif
  dispatch(requestVpnClientToIpList());
  return getVpnClientToIpList(idNetwork)
    .then((res = {}) => dispatch(receiveVpnClientToIpList(res)));
};

/**
 * VPN ACTIONS Client To Ip List
 */

const requestVpnClientStatusList = () => ({
  type: REQUEST_VPN_STATUS_LIST,
});

const receiveVpnClientStatusList = ({
  error = true,
  data = {},
  message = '',
}) => {
  const vpnStatus = {};
  // let  = [];
  const { clientList = [], routingTable = [] } = data;
  // const vpnStatus = {};
  const vpnClientStatusList = clientList.map((i) => {
    const { commonName, realAddress } = i;
    const routingInfo = routingTable.find((e) => e.commonName === commonName) || {};
    const singleVpnInfo = {
      ...i,
      name: commonName,
      realAddress: realAddress.split(':')[0],
      remotePort: realAddress.split(':')[1],
      ...routingInfo,
    };
    vpnStatus[commonName] = singleVpnInfo;
    return singleVpnInfo;
  });
  return {
    type: RECEIVE_VPN_STATUS_LIST,
    payload: {
      error,
      message,
      vpnStatus,
      vpnClientStatusList,
      // vpnCertList,
      // vpnNetworkInfo,
    },
  };
};

export const fetchVpnClientStatusList = (idNetwork) => (dispatch) => { // loadMore notif
  dispatch(requestVpnClientStatusList());
  return getVpnClientStatus(idNetwork)
    .then((res = {}) => dispatch(receiveVpnClientStatusList(res)));
};

const requestStaticNetworkList = () => ({
  type: REQUEST_STATIC_NETWORK_LIST,
});

const receiveStaticNetworkList = ({
  error = true,
  data = [],
  message = '',
}) => {
  const netRouting = data.map((item, index) => {
    item.id = index;
    return item;
  }).filter((i) => i.face === 'vpn0');
  return {
    type: RECEIVE_STATIC_NETWORK_LIST,
    payload: {
      error,
      message,
      data: netRouting,
    },
  };
};

export const fetchStaticNetworkList = () => (dispatch) => { // loadMore notif
  dispatch(requestStaticNetworkList());
  return getNetworkStaticRoutes()
    .then((res = {}) => dispatch(receiveStaticNetworkList(res)));
};

/*
 * USER INFO ACTIONS
 */

export const setUserInfoStoreItems = (userItems) => ({
  type: SET_USER_INFO_STORE_ITEMS,
  payload: userItems,
});

const requestUserInfo = () => ({
  type: REQUEST_USER_INFO,
});

const receiveUserInfo = ({
  error = true,
  data = [],
  message = '',
}) => ({
  type: RECEIVE_USER_INFO,
  payload: {
    error,
    message,
    data: data[0],
  },
});

export const fetchUser = () => (dispatch) => { // loadMore notif
  dispatch(requestUserInfo());
  // return fetchUserInfo()
  //   .then((res = {}) => dispatch(receiveUserInfo(res)));
};

/*
       for users
*/
const requestUsersList = () => ({
  type: USERS_REQUEST_LIST,
});

const receiveUsersList = ({
  error = true,
  data = [],
  message = '',
}) => {
  return {
    type: USERS_RECEIVE_LIST,
    payload: {
      error,
      message,
      usersList: data,
    },
  };
};

export const fetchUsersList = () => (dispatch) => {
  dispatch(requestUsersList());
  return getUsersByClientId()
    .then((res = {}) => dispatch(receiveUsersList(res)));
};

export const clearUsersData = () => ({
  type: USERS_CLEAR_DATA,
});
export const addUser = (newUser) => ({
  type: USER_ADD,
  payload: {
    newUser,
  },
});
export const addNewUser = (newUserData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    createUser(newUserData)
      .then((createdUser) => {
        if (!createdUser.error) {
          dispatch(addUser(createdUser));
          resolve(createdUser);
        } else {
          reject(createdUser.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred');
      });
  });
};

export const updateUser = (updatedUser) => ({
  type: USER_UPDATE,
  payload: {
    updatedUser,
  },
});
export const updateExistingUser = (userid, updatedUserData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    updateUserById(userid, updatedUserData)
      .then((updatedUser) => {
        if (!updatedUser.error) {
          dispatch(updateUser(updatedUser));
          resolve('User updated successfully.');
        } else {
          reject(updatedUser.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while updating the user.');
      });
  });
};

export const removeUser = (userid) => ({
  type: USER_REMOVE,
  payload: {
    userid,
  },
});
export const removeExistingUser = (userid) => (dispatch) => {
  return new Promise((resolve, reject) => {
    deleteUserById(userid)
      .then((result) => {
        if (!result.error) {
          dispatch(removeUser(userid));
          resolve('User removed successfully.');
        } else {
          reject(result.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while removing the user.');
      });
  });
};
/*
       for devices
*/
export const clearDevicesData = () => ({
  type: DEVICES_CLEAR_DATA,
});
const requestDevicesList = () => ({
  type: DEVICES_REQUEST_LIST,
});

const receiveDevicesList = ({
  error = true,
  data = [],
  message = '',
}) => {
  return {
    type: DEVICES_RECEIVE_LIST,
    payload: {
      error,
      message,
      devicesList: data,
    },
  };
};

export const fetchDevicesList = () => (dispatch) => {
  dispatch(requestDevicesList());
  return getAllDevices()
    .then((res = {}) => dispatch(receiveDevicesList(res)));
};

export const addDevice = (newDevice) => ({
  type: DEVICE_ADD,
  payload: {
    newDevice,
  },
});
export const addNewDevice = (newDeviceData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    createDevice(newDeviceData)
      .then((createdDevice) => {
        if (!createdDevice.error) {
          dispatch(addDevice(createdDevice));
          resolve(createdDevice);
        } else {
          reject(createdDevice.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred');
      });
  });
};

export const updateDevice = (updatedDevice) => ({
  type: DEVICE_UPDATE,
  payload: {
    updatedDevice,
  },
});
export const updateExistingDevice = (deviceid, updatedDeviceData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    updateDeviceById(deviceid, updatedDeviceData)
      .then((updatedDevice) => {
        if (!updatedDevice.error) {
          dispatch(updateDevice(updatedDevice));
          resolve('Device updated successfully.');
        } else {
          reject(updatedDevice.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while updating the device.');
      });
  });
};

export const removeDevice = (deviceId) => ({
  type: DEVICE_REMOVE,
  payload: {
    deviceId,
  },
});
export const removeExistingDevice = (deviceId, sn) => (dispatch) => {
  return new Promise((resolve, reject) => {
    deleteDeviceById(deviceId, sn)
      .then((result) => {
        if (!result.error) {
          dispatch(removeDevice(deviceId));
          resolve('Device removed successfully.');
        } else {
          reject(result.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while removing the device.');
      });
  });
};
export const UpdateExistingDeviceFieldById = (deviceId, deviceFieldData) => ({
  type: DEVICE_UPDATE_FIELD_BY_ID,
  payload: {
    deviceId, deviceFieldData,
  },
});
export const updateExistingDeviceFieldById = (deviceId, deviceFieldData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    updateDeviceFieldById(deviceId, deviceFieldData)
      .then((updatedDevice) => {
        if (!updatedDevice.error) {
          dispatch(UpdateExistingDeviceFieldById(deviceId, deviceFieldData));
          resolve('Device field updated successfully.');
        } else {
          reject(updatedDevice.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred');
      });
  });
};

// export const uploadDeviceConfigFile = (deviceconfigfile) => ({
//   type: DEVICE_UPLOAD_CONFIG_FILE,
//   payload: {
//     deviceconfigfile,
//   },
// });
// export const UploadDeviceConfigFile = (deviceid, deviceconfigfile) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     updateDeviceFieldById(deviceid, deviceconfigfile)
//       .then((deviceconfigfile) => {
//         if (!deviceconfigfile.error) {
//           dispatch(uploadDeviceConfigFile(deviceconfigfile));
//           resolve('Device config uploaded successfully.');
//         } else {
//           reject(deviceconfigfile.errMsg);
//         }
//       })
//       .catch((error) => {
//         reject(error.message || 'An error occurred');
//       });
//   });
// };

/*
       for Clients
*/
export const clearClientsData = () => ({
  type: CLIENTS_CLEAR_DATA,
});
const requestClientsList = () => ({
  type: CLIENTS_REQUEST_LIST,
});

const receiveClientsList = ({
  error = true,
  data = [],
  message = '',
}) => {
  return {
    type: CLIENTS_RECEIVE_LIST,
    payload: {
      error,
      message,
      clientsList: data,
    },
  };
};

export const fetchClientsList = () => (dispatch) => {
  dispatch(requestClientsList());
  return getAllClients()
    .then((res = {}) => dispatch(receiveClientsList(res)));
};

export const addClient = (newClient) => ({
  type: CLIENT_ADD,
  payload: {
    newClient,
  },
});
export const addNewClient = (newClientData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    createClient(newClientData)
      .then((createdClient) => {
        if (!createdClient.error) {
          dispatch(addClient(createdClient));
          resolve(createdClient);
        } else {
          reject(createdClient.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred');
      });
  });
};

export const updateClient = (updatedClient) => ({
  type: CLIENT_UPDATE,
  payload: {
    updatedClient,
  },
});
export const updateExistingClient = (clientid, updatedClientData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    updateClientById(clientid, updatedClientData)
      .then((updatedClient) => {
        if (!updatedClient.error) {
          dispatch(updateClient(updatedClient));
          resolve('Client updated successfully.');
        } else {
          reject(updatedClient.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while updating the client.');
      });
  });
};

export const removeClient = (clientId) => ({
  type: CLIENT_REMOVE,
  payload: {
    clientId,
  },
});
export const removeExistingClient = (clientId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    deleteClientById(clientId)
      .then((result) => {
        if (!result.error) {
          dispatch(removeClient(clientId));
          resolve('Client removed successfully.');
        } else {
          reject(result.errMsg);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while removing the client.');
      });
  });
};
// dashboard data

const requestClientDashboard = () => ({
  type: DASHBOARD_REQUEST,
});

const receiveClientDashboard = ({
  error = true,
  data = [],
  message = '',
}) => {
  return {
    type: DASHBOARD_RETRIVE,
    payload: {
      error,
      message,
      devicesPeerUser: data.devicesPeerUser,
      devices: data.devices,
      devices_data: data.devices_data,
      selectedDevice: null,
    },
  };
};

export const fetchClientDasboard = (queryParams) => (dispatch) => {
  dispatch(requestClientDashboard());
  return getClientDashboardData(queryParams)
    .then((res = {}) => dispatch(receiveClientDashboard(res)));
};
export const clearClientDashboardData = () => ({
  type: DASHBOARD_CLEAR_DATA,
});

export const setSelectedDeviceClientDashboard = ({
  selectedDevice,
}) => {
  return {
    type: SET_SELECTED_DEVICE,
    payload: {
      selectedDevice,
    },
  };
};

/*
    for client networks
*/

export const setSelectedClientNetwork = ({
  selectedClientNetwork,
}) => {
  return {
    type: SET_SELECTED_CLIENT_NETWORK,
    payload: {
      selectedClientNetwork,
    },
  };
};
export const clearClientsNetworksData = () => ({
  type: CLIENT_NETWORK_CLEAR_DATA,
});

export const setDefaultClientNetwork = ({
  defaultClientNetwork,
}) => {
  return {
    type: SET_DEFAULT_CLIENT_NETWORK,
    payload: {
      defaultClientNetwork,
    },
  };
};

const requestClientsNetworksList = () => ({
  type: CLIENT_NETWORK_REQUEST,
});

const receiveClientsNetworksList = ({
  error = true,
  data = [],
  message = '',
}) => {
  return {
    type: CLIENT_NETWORK_RETRIVE,
    payload: {
      error,
      message,
      clientNetworks: data,
    },
  };
};

export const fetchClientsNetworksList = () => (dispatch) => {
  dispatch(requestClientsNetworksList());
  return getAllNetworks()
    .then((res = {}) => dispatch(receiveClientsNetworksList(res)));
};

export const addClientNetwork = (newClientNetwork) => ({
  type: CLIENT_NETWORK_ADD,
  payload: {
    newClientNetwork,
  },
});
export const addNewClientNetwork = (newClientNetworkData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    createNetwork(newClientNetworkData)
      .then((createdClientNetwork) => {
        if (!createdClientNetwork.error) {
          dispatch(addClientNetwork(createdClientNetwork));
          resolve(createdClientNetwork);
        } else {
          reject(createdClientNetwork.message);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred');
      });
  });
};

export const updateClientNetwork = (updatedClientNetwork) => ({
  type: CLIENT_NETWORK_UPDATE,
  payload: {
    updatedClientNetwork,
  },
});
export const updateExistingClientNetwork = (clientnetworkid, updatedClientNetwork) => (dispatch) => {
  return new Promise((resolve, reject) => {
    updateNetworkById(clientnetworkid, updatedClientNetwork)
      .then((updatedClientNetwork) => {
        if (!updatedClientNetwork.error) {
          dispatch(updateDevice(updatedClientNetwork));
          resolve('Networks updated successfully.');
        } else {
          reject(updatedClientNetwork.message);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while updating the client network.');
      });
  });
};

export const removeClientNetwork = (clientnetworkid) => ({
  type: CLIENT_NETWORK_REMOVE,
  payload: {
    clientnetworkid,
  },
});
export const removeExistingClientNetwork = (clientnetworkid, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    deleteNetworkById(clientnetworkid, data)
      .then((result) => {
        if (!result.error) {
          dispatch(removeClientNetwork(clientnetworkid));
          resolve('Network removed successfully.');
        } else {
          reject(result.message);
        }
      })
      .catch((error) => {
        reject(error.message || 'An error occurred while removing the client network.');
      });
  });
};

/*
    for login data
*/

export const setLoginData = ({
  loginData,
}) => {
  return {
    type: SET_LOGINDATA,
    payload: {
      loginData,
    },
  };
};
export const clearLoginData = () => ({
  type: CLEAR_LOGINDATA,
});

/**
 * Network vpn status data
 */

const requestClientsNetworkVpnStatus = () => ({
  type: VPN_CLIENT_SERVER_VPN_STATUS_REQUEST_LIST,
});

const receiveClientsNetworkVpnStatus = ({
  error = true,
  data = [],
  message = '',
}) => {
  return {
    type: VPN_CLIENT_SERVER_VPN_STATUS_RECEIVE_LIST,
    payload: {
      error,
      message,
      clientNetworkData: data,
    },
  };
};

export const fetchclientNetworkData = (idNetwork) => (dispatch) => {
  dispatch(requestClientsNetworkVpnStatus());
  return getCleintNetworkVpnStatus(idNetwork)
    .then((res = {}) => dispatch(receiveClientsNetworkVpnStatus(res)));
};

export const clearClientServerVpnStatusData = () => ({
  type: VPN_CLIENT_SERVER_VPN_STATUS_CLEAR_DATA,
});

export const fetchAll = () => (dispatch) => {
  dispatch(fetchClientsNetworksList());// client networks
  dispatch(fetchStaticNetworkList());
  dispatch(fetchUsersList());// users
  dispatch(fetchDevicesList());// devices
  dispatch(fetchClientsList());// clients
};

// clear all data
export const ClearAll = () => (dispatch) => {
  dispatch(clearDevicesData());
  dispatch(clearUsersData());
  dispatch(clearClientsData());
  dispatch(clearClientDashboardData());
  dispatch(clearClientsNetworksData());
  dispatch(clearLoginData());
  dispatch(clearClientServerVpnStatusData());
};

/* eslint-disable no-case-declarations */
import * as actionTypes from '../actionTypes';

export const initialState = {
  isFetching: false,
  devicesList: [],
  fetchTs: new Date().getTime(),
};

const devicesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.DEVICES_REQUEST_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.DEVICES_RECEIVE_LIST:
      return {
        ...state,
        isFetching: false,
        devicesList: payload.devicesList,
      };
    case actionTypes.DEVICE_ADD:
      return {
        ...state,
        devicesList: [...state.devicesList, payload.newDevice],
      };
    case actionTypes.DEVICE_UPDATE:
      const updatedDeviceIndex = state.devicesList.findIndex((device) => device.id === payload.updatedDevice.id);
      const updatedDevicesList = [...state.devicesList];
      updatedDevicesList[updatedDeviceIndex] = payload.updatedDevice;
      return {
        ...state,
        devicesList: updatedDevicesList,
      };
      // case actionTypes.DEVICE_UPLOAD_CONFIG_FILE:
      //   const updatedConfiguredDevicesList = state.devicesList.map((device) => {
      //     if (device.id === payload.updatedDevice.id) {
      //       return {
      //         ...device,
      //         configFilePath: payload.updatedDevice.configFilePath,
      //       };
      //     }
      //     return device;
      //   });
      //   return {
      //     ...state,
      //     devicesList: updatedConfiguredDevicesList,
      //   };

    case actionTypes.DEVICE_UPDATE_FIELD_BY_ID:
      const { deviceId, deviceFieldData } = payload; // Correct the destructuring here
      const { field, value } = deviceFieldData;
      const DeviceIndex = state.devicesList.findIndex((device) => device.id === deviceId);
      const newDevicesList = [...state.devicesList];
      newDevicesList[DeviceIndex][field] = Number(value);
      return {
        ...state,
        devicesList: newDevicesList,
      };
    case actionTypes.DEVICE_REMOVE:
      return {
        ...state,
        devicesList: state.devicesList.filter((device) => device.id !== payload.deviceId),
      };
    case actionTypes.DEVICES_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
export default devicesReducer;

import { combineReducers } from 'redux';
import customizationReducer from './Reducer/customizationReducer';
import vpnReducer from './Reducer/vpnReducer';
import networkReducer from './Reducer/networkReducer';
import usersReducer from './Reducer/usersReducer';
import devicesReducer from './Reducer/devicesReducer';
import clientsReducer from './Reducer/clientsReducer';
import dashboardReducer from './Reducer/dashboardReducer';
import clientNetworkReducer from './Reducer/clientNetworkReducer';
import loginReducer from './Reducer/loginReducer';
import clientNetworkVpnStatusReducer from './Reducer/vpnstatusReducer';

const reducer = combineReducers({
  customization: customizationReducer,
  vpn: vpnReducer,
  network: networkReducer,
  users: usersReducer,
  devices: devicesReducer,
  clients: clientsReducer,
  dashboard: dashboardReducer,
  clientnetworks: clientNetworkReducer,
  login: loginReducer,
  clientnetworkvpnstatus: clientNetworkVpnStatusReducer,
});

export default reducer;

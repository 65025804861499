import NavigationOutlinedIcon from '@mui/icons-material/NavigationOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import GamesOutlinedIcon from '@mui/icons-material/GamesOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import FilterVintageOutlinedIcon from '@mui/icons-material/FilterVintageOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import ClassIcon from '@mui/icons-material/Class';
import SubjectIcon from '@mui/icons-material/Subject';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import SettingsApplicationsOutlined from '@mui/icons-material/SettingsApplicationsOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import NoteIcon from '@mui/icons-material/Note';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import HubIcon from '@mui/icons-material/Hub';
import LanIcon from '@mui/icons-material/Lan';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const icons = {
  LanIcon,
  NetworkIcon: HubIcon,
  VpnStatusIcon: QueryStatsIcon,
  vpnCertificateIcon: WorkspacePremiumIcon,

  NavigationOutlinedIcon,
  HomeOutlinedIcon,
  FormatUnderlinedOutlinedIcon,
  GamesOutlinedIcon,
  LoyaltyOutlinedIcon,
  ForumOutlinedIcon,
  DescriptionOutlinedIcon,
  GridOnOutlinedIcon,
  TableChartOutlinedIcon,
  AccountTreeOutlinedIcon,
  CallToActionOutlinedIcon,
  NotificationsNoneOutlinedIcon,
  AppsOutlinedIcon,
  SmsOutlinedIcon,
  FilterVintageOutlinedIcon,
  FormatColorTextOutlinedIcon,
  ContactSupportOutlinedIcon,
  LayersOutlinedIcon,
  BlockOutlinedIcon,
  ChromeReaderModeOutlinedIcon,
  FiberManualRecordOutlinedIcon,
  EditAttributesOutlinedIcon,
  FaceOutlinedIcon,
  RadioButtonUncheckedOutlinedIcon,
  QuestionAnswerOutlinedIcon,
  AccountCircleOutlinedIcon,
  ShoppingCartOutlinedIcon,
  MailOutlineRoundedIcon,
  SpeakerNotesOutlinedIcon,
  CardGiftcardOutlinedIcon,
  ErrorOutlineRoundedIcon,
  HourglassEmptyRoundedIcon,
  MonetizationOnOutlinedIcon,
  AssignmentIndOutlinedIcon,
  SecurityOutlinedIcon,
  HelpOutlineOutlinedIcon,
  TranslateIcon,
  FormatListNumberedRtlIcon,
  ClassIcon,
  SubjectIcon,
  PeopleAltOutlined,
  AttachMoneyOutlinedIcon,
  AccountBalanceWalletOutlinedIcon,
  SettingsApplicationsOutlined,
  DashboardOutlined,
  SchoolIcon,
  NoteIcon,
};

export default icons;

/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';

import { IntlProvider } from 'react-intl';

import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ToastContainer } from 'react-toastify';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';

import customTheme from '../themes';
import Routes from '../Routes';
import NavigationScroll from './NavigationScroll';

function loadLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('../compiled-lang/fr.json');
    case 'en':
      return import('../compiled-lang/en.json');
    default:
      return import('../compiled-lang/en.json');
  }
}

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
function App() {
  const customization = useSelector((state) => state.customization);
  const [messages, setMessages] = useState();

  useEffect(() => {
    loadLocaleData(customization.locale).then((d) => {
      setMessages(d.default);
    });
  }, [customization]);

  return (
    <>
      {messages && (
        <IntlProvider locale={customization.locale} defaultLocale="en" messages={messages}>
          <CssBaseline />
          <NavigationScroll>
            <StylesProvider jss={jss}>
              <ThemeProvider theme={customTheme(customization)}>
                <Routes />
                <ToastContainer theme={customization.navType} hideProgressBar closeOnClick autoClose={5000} />
              </ThemeProvider>
            </StylesProvider>
          </NavigationScroll>
        </IntlProvider>
      )}
    </>
  );
}

export default App;

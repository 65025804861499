import RouterIcon from '@mui/icons-material/Router';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import icons from './component/Icons/MaterialIcons';

export default {
  items: [{
    id: 'navigation',
    title: 'Luceor WEB Manager',
    caption: 'Powered by LUCEOR LABS',
    type: 'group',
    icon: icons.NavigationOutlinedIcon,
    children: [
      // {
      //   id: 'Dasboard',
      //   title: 'Dashboard',
      //   type: 'item',
      //   icon: icons.HomeOutlinedIcon,
      //   url: '/dashboard',
      //   chip: {
      //     label: 'new',
      //     error: true,
      //     size: 'small',
      //   },
      // },
      {
        id: 'Dasboard',
        title: 'Client Dashboard',
        type: 'item',
        icon: icons.DashboardOutlined,
        url: '/dashboard2',
      },
      {
        id: 'Dasboard2',
        title: 'VPN Overview',
        type: 'item',
        icon: icons.HomeOutlinedIcon,
        url: '/dashboard/vpn',
      },
      // {
      //   id: 'VPN',
      //   title: 'VPN',
      //   type: 'item',
      //   icon: icons.NetworkIcon,
      //   url: '/vpn',
      // },
      // {
      //   id: 'vpn Status',
      //   title: 'VPN Status',
      //   type: 'item',
      //   icon: icons.VpnStatusIcon,
      //   url: '/vpn-status',
      // },
      // {
      //   id: 'network2',
      //   title: 'Network2',
      //   type: 'item',
      //   icon: icons.LanIcon,
      //   url: '/network2',
      //   chip: {
      //     label: 'new',
      //     error: true,
      //     size: 'small',
      //   },
      //   // disabled: true,
      // },
      // {
      //   id: 'network',
      //   title: 'Network',
      //   type: 'item',
      //   icon: icons.LanIcon,
      //   url: '/network',
      // // disabled: true,
      // },
      // {
      //   id: 'people',
      //   title: 'People',
      //   type: 'item',
      //   icon: icons.PeopleAltOutlined,
      //   url: '/people',
      //   // disabled: true,
      // },
      // {
      //   id: 'Login2',
      //   title: 'Login2',
      //   type: 'item',
      //   icon: icons.DashboardOutlined,
      //   // url: '/login2',
      //   url: '/utils/util-icons',
      //   // url:"/utils/util-typography",
      //   // disabled: true,
      // },
      // {
      //   id: 'Login3',
      //   title: 'Login',
      //   type: 'item',
      //   icon: icons.DashboardOutlined,
      //   url: '/utils/util-typography',

      //   // url: '/login3',
      //   // disabled: true,
      // },
      // {
      //   id: 'home',
      //   title: 'Home',
      //   type: 'item',
      //   icon: icons.DashboardOutlined,
      //   url: '/home',
      //   disabled: true,
      // },

      // {
      //   id: 'settings',
      //   title: 'settings ',
      //   type: 'item',
      //   icon: icons.SettingsApplicationsOutlined,
      //   url: '/settings',
      //   chip: {
      //     label: 'comming',
      //     error: true,
      //     size: 'small',
      //   },
      //   disabled: true,
      // },
      // {
      //   id: 'notifications',
      //   title: 'Notifications ',
      //   type: 'item',
      //   icon: icons.NotificationsNoneOutlinedIcon,
      //   url: '/notifications',
      //   chip: {
      //     label: 'comming',
      //     error: true,
      //     size: 'small',
      //   },
      //   disabled: true,
      // },
      {
        id: 'devices',
        title: 'Devices',
        type: 'item',
        icon: RouterIcon,
        url: '/devices',
      // disabled: true,
      },
      {
        id: 'networks',
        title: 'Client Networks',
        type: 'item',
        icon: SettingsSuggestIcon,
        url: '/networks',
      // disabled: true,
      },
      {
        id: 'VPN Cert',
        title: 'Certificate List',
        type: 'item',
        icon: icons.vpnCertificateIcon,
        url: '/cert',
      },
      {
        id: 'clients',
        title: 'Clients',
        type: 'item',
        icon: icons.PeopleAltOutlined,
        url: '/clients',
      // disabled: true,
      },
      {
        id: 'User',
        title: 'Users ',
        type: 'item',
        icon: icons.AccountCircleOutlinedIcon,
        url: '/users',
        // disabled: true,
      },
    // {
    //   id: 'transactions',
    //   title: 'transaction',
    //   type: 'item',
    //   icon: icons['AccountBalanceWalletOutlinedIcon'],
    //   url: '/incomes',
    //   hip: {
    //     label: 'new',
    //     error: true,
    //     size: 'small',
    //   },
    //   // disabled: true,
    // },
    // {
    //   id: 'courses',
    //   title: 'courses',
    //   type: 'item',
    //   icon: icons.SubjectIcon,
    //   url: '/courses',
    //   target: true,
    // },
    // {
    //   id: 'login-1',
    //   title: 'login',
    //   type: 'item',
    //   url: '/login',
    //   target: true,
    // },
    // {
    //   id: 'system',
    //   title: 'system',
    //   type: 'item',
    //   icon: icons['SettingsApplicationsOutlined'],
    //   url: '/system',
    //   // disabled: true,
    // },
    // {
    //   id: 'inventory',
    //   title: 'Inventory',
    //   type: 'item',
    //   url: '/inventory',
    //   disabled: true,
    // },
    // {
    //   id: 'subscriber',
    //   title: 'Subscriber',
    //   type: 'item',
    //   icon: icons['PeopleAltOutlined'],
    //   url: '/subscriber',
    // },
    // {
    //   id: 'bills',
    //   title: 'bills',
    //   type: 'item',
    //   icon: icons['HomeOutlinedIcon'],
    //   url: '/dashboard/home',
    //   // disabled: true,
    // },
    // {
    //   id: 'statementOfGrades ',
    //   title: 'Notes',
    //   type: 'item',
    //   icon: icons['NoteIcon'],
    //   url: '/statementOfGrades',
    // },
    // {
    //   id: 'associationConfig',
    //   title: 'Configuration',
    //   type: 'item',
    //   icon: icons['TuneOutlinedIcon'],
    //   url: '/associationConfig',
    // },
    // {
    //   id: 'faculty',
    //   title: 'Faculty',
    //   type: 'item',
    //   icon: icons['HomeOutlinedIcon'],
    //   url: '/dashboard/home',
    //   disabled: true,
    // },
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   type: 'item',
    //   icon: icons['HomeOutlinedIcon'],
    //   url: '/dashboard/default',
    // },
    // {
    //   id: 'settings',
    //   title: 'Settings',
    //   type: 'collapse',
    //   icon: icons.SettingsApplicationsOutlined,
    //   chip: {
    //     label: 'NEW',
    //     error: true,
    //     size: 'small',
    //   },
    //   // disabled: true,
    //   children: [
    //     {
    //       id: 'employees',
    //       title: 'Employees',
    //       type: 'item',
    //       // disabled: true,
    //       url: '/settings/employees',
    //     },
    //     {
    //       id: 'jobs',
    //       title: 'Jobs',
    //       type: 'item',
    //       // disabled: true,
    //       url: '/settings/jobs',
    //     },
    //     {
    //       id: 'studyLvl',
    //       title: 'Study LVL',
    //       type: 'item',
    //       // disabled: true,
    //       url: '/settings/studyLvls',
    //     },
    //   ],
    // },
      // {
      //     id: 'widget',
      //     title: 'Widget',
      //     type: 'collapse',
      //     icon: icons['CardGiftcardOutlinedIcon'],
      //     chip: {
      //         label: 'Pro',
      //         error: true,
      //         size: 'small',
      //     },
      //     children: [
      //         {
      //             id: 'statistic',
      //             title: 'Statistic',
      //             type: 'item',
      //             url: '/widget/statistic',
      //         },
      //         {
      //             id: 'data',
      //             title: 'Data',
      //             type: 'item',
      //             url: '/widget/data',
      //         },
      //         {
      //             id: 'chart',
      //             title: 'Chart',
      //             type: 'item',
      //             url: '/widget/chart',
      //         },
      //     ],
      // },
      // {
      //     id: 'rtl',
      //     title: 'RTL Layout',
      //     type: 'item',
      //     icon: icons['FormatListNumberedRtlIcon'],
      //     url: '/rtl',
      //     target: true,
      // },
    ],
  },
  // {
  //   id: 'application',
  //   title: 'Application',
  //   caption: 'Prebuild Application',
  //   type: 'group',
  //   icon: icons.NavigationOutlinedIcon,
  //   children: [
  //     {
  //       id: 'user',
  //       title: 'User',
  //       type: 'collapse',
  //       icon: icons.AccountCircleOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'account',
  //           title: 'Account',
  //           type: 'item',
  //           url: '/user/account',
  //         },
  //         {
  //           id: 'profile',
  //           title: 'Profile',
  //           type: 'item',
  //           url: '/user/profile',
  //         },
  //         {
  //           id: 'socialprofile',
  //           title: 'Socialprofile',
  //           type: 'item',
  //           url: '/user/socialprofile',
  //         },
  //         {
  //           id: 'card',
  //           title: 'Card',
  //           type: 'item',
  //           url: '/user/card',
  //         },
  //         {
  //           id: 'list',
  //           title: 'List',
  //           type: 'item',
  //           url: '/user/list',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'ecommerce',
  //       title: 'E-commerce',
  //       type: 'collapse',
  //       icon: icons.ShoppingCartOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         size: 'small',
  //         error: true,
  //       },
  //       children: [
  //         {
  //           id: 'account',
  //           title: 'Account',
  //           type: 'item',
  //           url: '/application/ecommerce/account',
  //         },
  //         {
  //           id: 'addproduct',
  //           title: 'Add Product',
  //           type: 'item',
  //           url: '/application/ecommerce/addproduct',
  //         },
  //         {
  //           id: 'Customerslist',
  //           title: 'Customers List',
  //           type: 'item',
  //           url: '/application/ecommerce/customerlist',
  //         },
  //         {
  //           id: 'orderlist',
  //           title: 'Order List',
  //           type: 'item',
  //           url: '/application/ecommerce/orderlist',
  //         },
  //         {
  //           id: 'orderdetails',
  //           title: 'Order Details',
  //           type: 'item',
  //           url: '/application/ecommerce/orderdetails',
  //         },
  //         {
  //           id: 'product',
  //           title: 'Product',
  //           type: 'item',
  //           url: '/application/ecommerce/product',
  //         },
  //         {
  //           id: 'productreview',
  //           title: 'Product Reviews',
  //           type: 'item',
  //           url: '/application/ecommerce/productreview',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'contacts',
  //       title: 'Contacts',
  //       type: 'collapse',
  //       icon: icons.AssignmentIndOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'contactlist',
  //           title: 'List',
  //           type: 'item',
  //           url: '/application/contacts/list',
  //         },
  //         {
  //           id: 'contactcard',
  //           title: 'Card',
  //           type: 'item',
  //           url: '/application/contacts/card',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'mail',
  //       title: 'Mail',
  //       type: 'item',
  //       url: '/application/mail',
  //       icon: icons.MailOutlineRoundedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'chat',
  //       title: 'Chat',
  //       type: 'item',
  //       url: '/application/chat',
  //       icon: icons.SpeakerNotesOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //   ],
  // },
  // {
  //   id: 'ui-element',
  //   title: 'UI Element',
  //   caption: 'Material UI Components',
  //   type: 'group',
  //   icon: icons.FormatUnderlinedOutlinedIcon,
  //   children: [
  //     {
  //       id: 'basic',
  //       title: 'Basic',
  //       type: 'collapse',
  //       icon: icons.GamesOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'accordion',
  //           title: 'Accordion',
  //           type: 'item',
  //           url: '/basic/accordion',
  //         },
  //         {
  //           id: 'avatar',
  //           title: 'Avatar',
  //           type: 'item',
  //           url: '/basic/avatar',
  //         },
  //         {
  //           id: 'badges',
  //           title: 'Badges',
  //           type: 'item',
  //           url: '/basic/badges',
  //         },
  //         {
  //           id: 'breadcrumb',
  //           title: 'Breadcrumb',
  //           type: 'item',
  //           url: '/basic/breadcrumb',
  //         },
  //         {
  //           id: 'cards',
  //           title: 'Cards',
  //           type: 'item',
  //           url: '/basic/cards',
  //         },
  //         {
  //           id: 'chip',
  //           title: 'Chip',
  //           type: 'item',
  //           url: '/basic/chip',
  //         },
  //         {
  //           id: 'list',
  //           title: 'List',
  //           type: 'item',
  //           url: '/basic/list',
  //         },
  //         {
  //           id: 'tabs',
  //           title: 'Tabs',
  //           type: 'item',
  //           url: '/basic/tabs',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'advance',
  //       title: 'Advance',
  //       type: 'collapse',
  //       icon: icons.LoyaltyOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'alert',
  //           title: 'Alert',
  //           type: 'item',
  //           url: '/advance/alert',
  //         },
  //         {
  //           id: 'dialog',
  //           title: 'Dialog',
  //           type: 'item',
  //           url: '/advance/dialog',
  //         },
  //         {
  //           id: 'pagination',
  //           title: 'Pagination',
  //           type: 'item',
  //           url: '/advance/pagination',
  //         },
  //         {
  //           id: 'progress',
  //           title: 'Progress',
  //           type: 'item',
  //           url: '/advance/progress',
  //         },
  //         {
  //           id: 'rating',
  //           title: 'Rating',
  //           type: 'item',
  //           url: '/advance/rating',
  //         },
  //         {
  //           id: 'snackbar',
  //           title: 'Snackbar',
  //           type: 'item',
  //           url: '/advance/snackbar',
  //         },
  //         {
  //           id: 'speeddial',
  //           title: 'Speeddial',
  //           type: 'item',
  //           url: '/advance/speeddial',
  //         },
  //         {
  //           id: 'timeline',
  //           title: 'Timeline',
  //           type: 'item',
  //           url: '/advance/timeline',
  //         },
  //         {
  //           id: 'toggle-button',
  //           title: 'Toggle Button',
  //           type: 'item',
  //           url: '/advance/toggle-button',
  //         },
  //         {
  //           id: 'treeview',
  //           title: 'Treeview',
  //           type: 'item',
  //           url: '/advance/treeview',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'ui-forms',
  //   title: 'Forms & Tables',
  //   type: 'group',
  //   icon: icons.ForumOutlinedIcon,
  //   children: [
  //     {
  //       id: 'forms',
  //       title: 'Forms',
  //       type: 'collapse',
  //       icon: icons.DescriptionOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'frm-autocomplete',
  //           title: 'Autocomplete',
  //           type: 'item',
  //           url: '/forms/frm-autocomplete',
  //         },
  //         {
  //           id: 'frm-button',
  //           title: 'Button',
  //           type: 'item',
  //           url: '/forms/frm-button',
  //         },
  //         {
  //           id: 'frm-checkbox',
  //           title: 'Checkbox',
  //           type: 'item',
  //           url: '/forms/frm-checkbox',
  //         },
  //         {
  //           id: 'frm-datetime',
  //           title: 'Date & Time',
  //           type: 'item',
  //           url: '/forms/frm-datetime',
  //         },
  //         {
  //           id: 'frm-listbox',
  //           title: 'Listbox',
  //           type: 'item',
  //           url: '/forms/frm-listbox',
  //         },
  //         {
  //           id: 'frm-radio',
  //           title: 'Radio',
  //           type: 'item',
  //           url: '/forms/frm-radio',
  //         },
  //         {
  //           id: 'frm-select',
  //           title: 'Select',
  //           type: 'item',
  //           url: '/forms/frm-select',
  //         },
  //         {
  //           id: 'frm-slider',
  //           title: 'Slider',
  //           type: 'item',
  //           url: '/forms/frm-slider',
  //         },
  //         {
  //           id: 'frm-switch',
  //           title: 'Switch',
  //           type: 'item',
  //           url: '/forms/frm-switch',
  //         },
  //         {
  //           id: 'frm-text-field',
  //           title: 'Text Field',
  //           type: 'item',
  //           url: '/forms/frm-text-field',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'tables',
  //       title: 'Table',
  //       type: 'collapse',
  //       icon: icons.TableChartOutlinedIcon,
  //       children: [
  //         {
  //           id: 'table-basic',
  //           title: 'Basic Table',
  //           type: 'item',
  //           url: '/tables/table-basic',
  //         },
  //         {
  //           id: 'dense-table',
  //           title: 'Dense Tables',
  //           type: 'item',
  //           url: '/tables/dense-table',
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //         {
  //           id: 'enhanced-table',
  //           title: 'Enhanced Tables',
  //           type: 'item',
  //           url: '/tables/enhanced-table',
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //         {
  //           id: 'data-table',
  //           title: 'Data Table',
  //           type: 'item',
  //           url: '/tables/data-table',
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //         {
  //           id: 'customized-table',
  //           title: 'Custom Table',
  //           type: 'item',
  //           url: '/tables/customized-table',
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //         {
  //           id: 'sticky-header-table',
  //           title: 'Fixed Header',
  //           type: 'item',
  //           url: '/tables/sticky-header-table',
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //         {
  //           id: 'collapse-table',
  //           title: 'Collapse Table',
  //           type: 'item',
  //           url: '/tables/collapse-table',
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   caption: 'Prebuild Pages',
  //   type: 'group',
  //   icon: icons.NavigationOutlinedIcon,
  //   children: [
  //     {
  //       id: 'sample-page',
  //       title: 'Sample Page',
  //       type: 'item',
  //       url: '/sample-page',
  //       icon: icons.ChromeReaderModeOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'multi-language',
  //       title: 'Multi-Language',
  //       type: 'item',
  //       url: '/multi-language',
  //       icon: icons.TranslateIcon,
  //     },
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: icons.SecurityOutlinedIcon,
  //       children: [
  //         {
  //           id: 'login-1',
  //           title: 'Login',
  //           type: 'item',
  //           url: '/application/login',
  //           target: true,
  //         },
  //         {
  //           id: 'register',
  //           title: 'Register',
  //           type: 'item',
  //           url: '/application/register',
  //           target: true,
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //         {
  //           id: 'forget-1',
  //           title: 'Forget Password',
  //           type: 'item',
  //           url: '/application/forgot-password',
  //           target: true,
  //           chip: {
  //             label: 'Pro',
  //             error: true,
  //             size: 'small',
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       id: 'price',
  //       title: 'Pricing',
  //       type: 'collapse',
  //       icon: icons.MonetizationOnOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'price1',
  //           title: 'Price 1',
  //           type: 'item',
  //           url: '/application/price/price1',
  //         },
  //         {
  //           id: 'price2',
  //           title: 'Price 2',
  //           type: 'item',
  //           url: '/application/price/price2',
  //         },
  //         {
  //           id: 'price3',
  //           title: 'Price 3',
  //           type: 'item',
  //           url: '/application/price/price3',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'error',
  //       title: 'Error',
  //       type: 'collapse',
  //       icon: icons.ErrorOutlineRoundedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //       children: [
  //         {
  //           id: 'error1',
  //           title: 'Error 1',
  //           type: 'item',
  //           url: '/pages/error/error1',
  //           target: true,
  //         },
  //         {
  //           id: 'error2',
  //           title: 'Error 2',
  //           type: 'item',
  //           url: '/pages/error/error2',
  //           target: true,
  //         },
  //       ],
  //     },
  //     {
  //       id: 'comingsoon',
  //       title: 'Coming soon',
  //       type: 'item',
  //       url: '/pages/comingsoon',
  //       icon: icons.HourglassEmptyRoundedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'documentation',
  //       title: 'Documentation',
  //       type: 'item',
  //       url: 'https://luceor.com',
  //       icon: icons.HelpOutlineOutlinedIcon,
  //       chip: {
  //         label: 'Help?',
  //         color: 'primary',
  //       },
  //       external: true,
  //       target: true,
  //     },
  //   ],
  // },
  // {
  //   id: 'utils',
  //   title: 'Utilities',
  //   type: 'group',
  //   icon: icons.AccountTreeOutlinedIcon,
  //   children: [
  //     {
  //       id: 'util-modal',
  //       title: 'Modal',
  //       type: 'item',
  //       url: '/utils/util-modal',
  //       icon: icons.CallToActionOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'util-tooltip',
  //       title: 'Tooltip',
  //       type: 'item',
  //       url: '/utils/util-tooltip',
  //       icon: icons.QuestionAnswerOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'util-popover',
  //       title: 'Popover',
  //       type: 'item',
  //       url: '/utils/util-popover',
  //       icon: icons.NotificationsNoneOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'util-popper',
  //       title: 'Popper',
  //       type: 'item',
  //       url: '/utils/util-popper',
  //       icon: icons.SmsOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'util-transitions',
  //       title: 'Transitions',
  //       type: 'item',
  //       url: '/utils/util-transitions',
  //       icon: icons.FilterVintageOutlinedIcon,
  //       chip: {
  //         label: 'Pro',
  //         error: true,
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'util-icons',
  //       title: 'Icons',
  //       type: 'item',
  //       url: '/utils/util-icons',
  //       icon: icons.AppsOutlinedIcon,
  //     },
  //     {
  //       id: 'util-typography',
  //       title: 'Typography',
  //       type: 'item',
  //       url: '/utils/util-typography',
  //       icon: icons.FormatColorTextOutlinedIcon,
  //     },
  //   ],
  // },
  // {
  //   id: 'support',
  //   title: 'Sidebar Features',
  //   type: 'group',
  //   icon: icons.ContactSupportOutlinedIcon,
  //   children: [
  //     {
  //       id: 'menu-level',
  //       title: 'Level 0',
  //       type: 'collapse',
  //       icon: icons.LayersOutlinedIcon,
  //       children: [
  //         {
  //           id: 'menu-level-1.1',
  //           title: 'Level 1',
  //           type: 'collapse',
  //           children: [
  //             {
  //               id: 'menu-level-2.1',
  //               title: 'Level 2',
  //               type: 'collapse',
  //               children: [
  //                 {
  //                   id: 'menu-level-3.1',
  //                   title: 'Level 3',
  //                   type: 'collapse',
  //                   children: [
  //                     {
  //                       id: 'menu-level-4.1',
  //                       title: 'Level 4',
  //                       type: 'collapse',
  //                       children: [
  //                         {
  //                           id: 'menu-level-5.1',
  //                           title: 'Level 5',
  //                           type: 'collapse',
  //                           children: [
  //                             {
  //                               id: 'menu-level-6.1',
  //                               title: 'Level 6',
  //                               type: 'collapse',
  //                               children: [
  //                                 {
  //                                   id: 'menu-level-7.1',
  //                                   title: 'Level 7',
  //                                   type: 'collapse',
  //                                   children: [
  //                                     {
  //                                       id: 'menu-level-8.1',
  //                                       title: 'Level 8',
  //                                       type: 'item',
  //                                       url: '#',
  //                                     },
  //                                   ],
  //                                 },
  //                               ],
  //                             },
  //                           ],
  //                         },
  //                       ],
  //                     },
  //                   ],
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       id: 'menu-level-subtitle',
  //       title: 'Caption Levels',
  //       caption: 'I am Subtitle',
  //       type: 'collapse',
  //       icon: icons.LayersOutlinedIcon,
  //       children: [
  //         {
  //           id: 'sub-menu-level-1.1',
  //           title: 'Level 1',
  //           caption: 'I am level 1 subtitle',
  //           type: 'collapse',
  //           children: [
  //             {
  //               id: 'sub-menu-level-2.1',
  //               title: 'Level 2',
  //               caption: 'I am level 2 subtitle',
  //               type: 'item',
  //               url: '#',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       id: 'disabled-menu',
  //       title: 'Disabled Menu',
  //       type: 'item',
  //       url: '#',
  //       icon: icons.BlockOutlinedIcon,
  //       disabled: true,
  //     },
  //     {
  //       id: 'oval-chip-menu',
  //       title: 'Oval Chip',
  //       type: 'item',
  //       url: '#',
  //       icon: icons.FiberManualRecordOutlinedIcon,
  //       chip: {
  //         label: '9',
  //         color: 'primary',
  //       },
  //     },
  //     {
  //       id: 'rounded-chip-menu',
  //       title: 'Rounded Chip',
  //       type: 'item',
  //       url: '#',
  //       icon: icons.EditAttributesOutlinedIcon,
  //       chip: {
  //         label: 'Rounded',
  //         color: 'secondary',
  //       },
  //     },
  //     {
  //       id: 'avatar-chip-menu',
  //       title: 'Avatar Chip',
  //       type: 'item',
  //       url: '#',
  //       icon: icons.FaceOutlinedIcon,
  //       chip: {
  //         label: 'Coded',
  //         color: 'primary',
  //         avatar: 'C',
  //         size: 'small',
  //       },
  //     },
  //     {
  //       id: 'outline-chip-menu',
  //       title: 'Outline Chip',
  //       type: 'item',
  //       url: '#',
  //       icon: icons.RadioButtonUncheckedOutlinedIcon,
  //       chip: {
  //         label: 'Outline',
  //         variant: 'outlined',
  //         color: 'primary',
  //       },
  //     },
  //   ],
  // },
  ],
};

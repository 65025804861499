/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MuiChip from '@material-ui/core/Chip';

function Chip(props) {
  const { ...rest } = props;

  return <MuiChip {...rest} />;
}

export default Chip;

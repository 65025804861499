/* eslint-disable no-case-declarations */
import * as actionTypes from '../actionTypes';

export const initialState = {
  isFetching: false,
  clientNetworks: [],
  defaultClientNetwork: null, // the default network for client
  selectedClientNetwork: null, // the selected network by client
  fetchTs: new Date().getTime(),
};

const clientNetworkReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CLIENT_NETWORK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.CLIENT_NETWORK_RETRIVE:
      const firstClientNetwork = payload.clientNetworks.length !== 0 ? payload.clientNetworks[0] : null;
      return {
        ...state,
        isFetching: false,
        clientNetworks: payload.clientNetworks,
        defaultClientNetwork: payload.clientNetworks.length !== 0 ? payload.clientNetworks[0] : null,
        selectedClientNetwork: state.selectedClientNetwork !== null ? state.selectedClientNetwork : firstClientNetwork,
      };
    case actionTypes.SET_DEFAULT_CLIENT_NETWORK:
      return {
        ...state,
        defaultClientNetwork: payload.defaultClientNetwork,
      };
    case actionTypes.SET_SELECTED_CLIENT_NETWORK:
      return {
        ...state,
        selectedClientNetwork: payload.selectedClientNetwork,
      };
    case actionTypes.CLIENT_NETWORK_ADD:
      return {
        ...state,
        clientNetworks: [...state.clientNetworks, payload.newClientNetwork],
      };
    case actionTypes.CLIENT_NETWORK_UPDATE:
      const updatedClientNetworkIndex = state.clientNetworks.findIndex((clientnetwork) => clientnetwork.idNetwork === payload.updatedClientNetwork.idNetwork);
      const updatedClientNetworksList = [...state.clientNetworks];
      updatedClientNetworksList[updatedClientNetworkIndex] = payload.updatedClientNetwork;
      return {
        ...state,
        clientNetworks: updatedClientNetworksList,
      };
    case actionTypes.CLIENT_NETWORK_REMOVE:
      return {
        ...state,
        clientNetworks: state.clientNetworks.filter((clientnetwork) => clientnetwork.idNetwork !== payload.clientnetworkid),
      };
    case actionTypes.CLIENT_NETWORK_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
export default clientNetworkReducer;

import {
  HTTP_HEADER_JSON,
} from './config';

export function getAllNetworks() {
  return fetch('/mtc/api/v1/clientnetwroks', {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function createNetwork(networkData) {
  return fetch('/mtc/api/v1/defaultclientnetwroks', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(networkData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function updateNetworkById(id, updatedData) {
  return fetch(`/mtc/api/v1/defaultclientnetwroks/${id}`, {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(updatedData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function deleteNetworkById(id, queryParams) {
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return fetch(`/mtc/api/v1/clientnetwroks/${id}?${queryString}`, {
    method: 'DELETE',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function startNetworkById(data) {
  return fetch('/mtc/api/v1/startclientnetwrok', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function stopNetworkById(data) {
  return fetch('/mtc/api/v1/stopclientnetwrok', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function getCleintNetworkVpnStatus(idNetwork) {
  return fetch(`/mtc/api/v1/clientnetworkvpnstatus?idNetwork=${idNetwork}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

import React, { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';
import useLocalStorage from './useLocalStorage';
import { signOut } from '../api/user';
import { ClearAll, setLoginData } from '../store/actions';

const AuthContext = createContext();

export function AuthProvider({ children, userData }) {
  const [user, setUser] = useLocalStorage('user', userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = async (data) => {
    setUser(data);
    dispatch(setLoginData({ loginData: data }));
    navigate('/dashboard2', { replace: true });
  };

  const logout = () => {
    signOut()
      .then((res) => {
        if (res && res.data && !res.error) {
          ClearAll();
          setUser(null);
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login', { replace: true });
        }
      });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

import {
  HTTP_HEADER_JSON,
} from './config';

export function getAllDevices() {
  return fetch('/mtc/api/v1/devices/client', {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function getDeviceById(id) {
  return fetch(`/mtc/api/v1/devices/${id}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function createDevice(deviceData) {
  return fetch('/mtc/api/v1/devices', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(deviceData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function updateDeviceById(id, updatedData) {
  return fetch(`/mtc/api/v1/devices/${id}`, {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(updatedData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function deleteDeviceById(id, sn) {
  return fetch(`/mtc/api/v1/devices/${id}?&sn=${sn}`, {
    method: 'DELETE',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}
export function updateDeviceFieldById(id, deviceField) {
  return fetch(`/mtc/api/v1/devices/${id}`, {
    method: 'PATCH',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(deviceField),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function uploadDeviceConfigFileById(id, configFileData) {
  return fetch(`/mtc/api/v1/devices/${id}`, {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(configFileData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function uploadBulkDeviceConfigFile(data) {
  return fetch('/mtc/api/v1/devices/bulkupload', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function unsetDeviceConfigById(id, sn) {
  return fetch(`/mtc/api/v1/unsetdevice/${id}?&sn=${sn}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

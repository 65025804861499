import * as actionTypes from '../actionTypes';

export const initialState = {
  loginData: null,
  fetchTs: new Date().getTime(),
};

const loginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_LOGINDATA: {
      return {
        ...state,
        loginData: payload.loginData,
      };
    }
    case actionTypes.CLEAR_LOGINDATA:
      return initialState;
    default:
      return state;
  }
};

export default loginReducer;

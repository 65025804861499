/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useTheme, useMediaQuery, Box, makeStyles, Tooltip,
  TextField, MenuItem, InputAdornment, Hidden, Button,
} from '@material-ui/core';

import GetAppIcon from '@mui/icons-material/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '250px',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
    borderRadius: '10px',
  },
  subHeader: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
    padding: '5px 15px',
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
  gridContainer: {
    padding: '10px',
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menuButton: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '35px',
    },
  },
  iconSelect: {
    color: '#fff',
    fontSize: '1.4rem',
  },
  selectColor: {
    color: '#fff',
    // padding: '0 !important',
    fontSize: '1rem',
    marginTop: '4px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
    },
  },
  underlineSelect: {
    ':before': {
      display: 'none',
    },
  },
  selectIcon: {
    marginRight: '4px',
    color: '#fff',
  },
}));

function Customization() {
  const classes = useStyles();

  // const theme = useTheme();
  const onClickExportLocalStorageToFile = () => {
    // const exportedData = localStorage.getItem('state')
    const element = document.createElement('a');
    const file = new Blob([localStorage.getItem('state') || ''], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    const ts = new Date().toJSON();
    element.download = `vpnData${ts}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  return (
    <Tooltip title="Download Data">
      <Button className={classes.menuButton} color="inherit" onClick={onClickExportLocalStorageToFile}>
        <GetAppIcon className={classes.menuIcon} />
      </Button>
    </Tooltip>
  );
}

export default Customization;

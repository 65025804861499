export function getCertificateList(idNetwork) {
  return fetch(`/mtc/api/v1/vpn/client/all?&idNetwork=${idNetwork}`)
    .then((res) => res.json())
  // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

export function getVpnClientToIpList(idNetwork) {
  return fetch(`/mtc/api/v1/vpn/client/ip/all?&idNetwork=${idNetwork}`)
    .then((res) => res.json())
  // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}
export function getPkiIndex(idNetwork) {
  return fetch(`/mtc/api/v1/vpn/pki/index?&idNetwork=${idNetwork}`)
    .then((res) => res.json())
  // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

export function getVpnClientStatus(idNetwork) {
  return fetch(`/mtc/api/v1/vpn/status?&idNetwork=${idNetwork}`)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
    });
}

export function assignCertifToDevice(data) {
  return fetch('/mtc/api/v1/vpn/assigncertif/conf', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => res.json());
}
export function genNewClientCertificate(data) {
  return fetch('/mtc/api/v1/vpn/client', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => res.json());
  // .then((r)=>{ console.log({r})})
  // .catch((err) => {
  //   console.error(err);
  // });
}

export function revokeClientCertificate(data) {
  return fetch('/mtc/api/v1/vpn/client', {
    method: 'delete',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => res.json());
  // .then((r)=>{ console.log({r})})
  // .catch((err) => {
  //   console.error(err);
  // });
}

function downloadFile(url, data) {
  // data servername,clientname,idNetwork
  const parts = url.split('/');
  const extractedPart = parts[parts.length - 1];
  console.log('extracted part', extractedPart, parts);
  return fetch(url, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      if (extractedPart === 'cert') {
        a.download = `${data.clientname}`;
      } else if (extractedPart === 'tlskey') {
        a.download = `${data.servername}.key`;
      } else {
        a.download = `${data.servername}`;
      }
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      // or you know, something with better UX...
    })
  // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

export const downloadClientCertificate = (data) => downloadFile('/mtc/api/v1/vpn/file/cert', data); // .p12| .ovpn
export const downloadVpnTlsKey = (data) => downloadFile('/mtc/api/v1/vpn/file/tlskey', data); // .tls
export const downloadVpnConfFile = (data) => downloadFile('/mtc/api/v1/vpn/file/conf', data); // .ovpn

export function downloadAllClientCertificates(data) {
  // data idNetwork
  return fetch('/mtc/api/v1/vpn/file/cert/all', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      const currentDate = new Date();
      const folderDateName = currentDate.toLocaleString().replace(/[, :/]+/g, '_');
      const folderName = `vpn_certificates_${folderDateName}.zip`;
      a.download = folderName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      // or you know, something with better UX...
    })
  // .then((r)=>{ console.log({r})})
    .catch((err) => {
      console.error(err);
    });
}

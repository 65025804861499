export const MENU_OPEN = '@customization/MENU_OPEN';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const THEME_TYPE = '@customization/THEME_TYPE';
export const DRAWER_OPEN = '@customization/DRAWER_OPEN';

export const SET_VPN_CLIENT_LIST = 'SET_VPN_CLIENT_LIST';
export const REQUEST_VPN_CLIENT_LIST = 'REQUEST_VPN_CLIENT_LIST,';
export const RECEIVE_VPN_CLIENT_LIST = 'RECEIVE_VPN_CLIENT_LIST';

export const SET_VPN_CERT_LIST = 'SET_VPN_CERT_LIST';
export const REQUEST_VPN_CERT_LIST = 'REQUEST_VPN_CERT_LIST,';
export const RECEIVE_VPN_CERT_LIST = 'RECEIVE_VPN_CERT_LIST';

export const REQUEST_PKI_INDEX_LIST = 'REQUEST_PKI_INDEX_LIST';
export const RECEIVE_PKI_INDEX_LIST = 'RECEIVE_PKI_INDEX_LIST';

export const SET_VPN_STATUS_LIST = 'SET_VPN_STATUS_LIST';
export const REQUEST_VPN_STATUS_LIST = 'REQUEST_VPN_STATUS_LIST,';
export const RECEIVE_VPN_STATUS_LIST = 'RECEIVE_VPN_STATUS_LIST';

export const SET_VPN_CLIENT_TO_IP_LIST = 'SET_VPN_CLIENT_TO_IP_LIST';
export const REQUEST_VPN_CLIENT_TO_IP_LIST = 'REQUEST_VPN_CLIENT_TO_IP_LIST,';
export const RECEIVE_VPN_CLIENT_TO_IP_LIST = 'RECEIVE_VPN_CLIENT_TO_IP_LIST';

// export const SET_VPN_CLIENT_LIST = 'SET_VPN_CLIENT_LIST';
// export const REQUEST_VPN_CLIENT_LIST = 'REQUEST_VPN_CLIENT_LIST,';
// export const RECEIVE_VPN_CLIENT_LIST = 'RECEIVE_VPN_CLIENT_LIST';

export const SET_STATIC_NETWORK_LIST = 'SET_STATIC_NETWORK_LIST';
export const REQUEST_STATIC_NETWORK_LIST = 'REQUEST_STATIC_NETWORK_LIST';
export const RECEIVE_STATIC_NETWORK_LIST = 'RECEIVE_STATIC_NETWORK_LIST';

export const SET_USER_INFO_STORE_ITEMS = 'SET_USER_INFO_STORE_ITEMS';
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO,';
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO';
export const SET_USER_CONFIG = 'SET_USER_CONFIG';
export const SET_NOTIF = 'SET_NOTIF';
export const RECEIVE_MORE_NOTIF = 'RECEIVE_MORE_NOTIF';
export const REQUEST_NOTIF = 'REQUEST_NOTIF';
export const INVALIDATE_NOTIF = 'INVALIDATE_NOTIF';

// for users
export const USERS_REQUEST_LIST = 'USERS_REQUEST_LIST';
export const USERS_RECEIVE_LIST = 'USERS_RECEIVE_LIST';
export const USER_ADD = 'USER_ADD';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_REMOVE = 'USER_REMOVE';
export const USERS_CLEAR_DATA = 'USERS_CLEAR_DATA';

// devices
export const DEVICES_REQUEST_LIST = 'DEVICES_REQUEST_LIST';
export const DEVICES_RECEIVE_LIST = 'DEVICES_RECEIVE_LIST';
export const DEVICE_ADD = 'DEVICE_ADD';
export const DEVICE_UPDATE = 'DEVICE_UPDATE';
export const DEVICE_REMOVE = 'DEVICE_REMOVE';
export const DEVICES_CLEAR_DATA = 'DEVICES_CLEAR_DATA';
export const DEVICE_UPLOAD_CONFIG_FILE = 'DEVICE_UPLOAD_CONFIG_FILE';
export const DEVICE_UPDATE_FIELD_BY_ID = 'DEVICE_UPDATE_FIELD_BY_ID';
export const DEVICE_UNSET_CONFIG = 'DEVICE_UNSET_CONFIG';

// clients
export const CLIENTS_REQUEST_LIST = 'CLIENTS_REQUEST_LIST';
export const CLIENTS_RECEIVE_LIST = 'CLIENTS_RECEIVE_LIST';
export const CLIENT_ADD = 'CLIENT_ADD';
export const CLIENT_UPDATE = 'CLIENT_UPDATE';
export const CLIENT_REMOVE = 'CLIENT_REMOVE';
export const CLIENTS_CLEAR_DATA = 'CLIENTS_CLEAR_DATA';

// dashboard
export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST';
export const DASHBOARD_RETRIVE = 'DASHBOARD_RETRIVE';
export const DASHBOARD_CLEAR_DATA = 'DASHBOARD_CLEAR_DATA';
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';

// client networks - ovpn
export const CLIENT_NETWORK_REQUEST = 'CLIENT_NETWORK_REQUEST';
export const CLIENT_NETWORK_RETRIVE = 'CLIENT_NETWORK_RETRIVE';
export const CLIENT_NETWORK_CLEAR_DATA = 'CLIENT_NETWORK_CLEAR_DATA';
export const SET_DEFAULT_CLIENT_NETWORK = 'SET_DEFAULT_CLIENT_NETWORK';
export const CLIENT_NETWORK_ADD = 'CLIENT_NETWORK_ADD';
export const CLIENT_NETWORK_REMOVE = 'CLIENT_NETWORK_REMOVE';
export const CLIENT_NETWORK_UPDATE = 'CLIENT_NETWORK_UPDATE';
export const SET_SELECTED_CLIENT_NETWORK = 'SET_SELECTED_CLIENT_NETWORK';

// login data
export const SET_LOGINDATA = 'SET_LOGINDATA';
export const CLEAR_LOGINDATA = 'CLEAR_LOGINDATA';

// client network vpn status (sent/recived bytes)
export const VPN_CLIENT_SERVER_VPN_STATUS_REQUEST_LIST = 'VPN_CLIENT_SERVER_VPN_STATUS_REQUEST_LIST';
export const VPN_CLIENT_SERVER_VPN_STATUS_RECEIVE_LIST = 'VPN_CLIENT_SERVER_VPN_STATUS_RECEIVE_LIST';
export const VPN_CLIENT_SERVER_VPN_STATUS_CLEAR_DATA = 'VPN_CLIENT_SERVER_VPN_STATUS_CLEAR_DATA';

/* eslint-disable no-case-declarations */
import * as actionTypes from '../actionTypes';

export const initialState = {
  isFetching: false,
  usersList: [],
  fetchTs: new Date().getTime(),
};

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.USERS_REQUEST_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.USERS_RECEIVE_LIST:
      return {
        ...state,
        isFetching: false,
        usersList: action.payload.usersList,
      };
    case actionTypes.USER_ADD:
      return {
        ...state,
        // usersList: [...state.usersList, payload.newUserData],
      };
    case actionTypes.USER_UPDATE:
      const updatedUsersIndex = state.usersList.findIndex((user) => user.idUser === payload.updatedUser.idUser);
      const updatedUsersList = [...state.usersList];
      updatedUsersList[updatedUsersIndex] = payload.updatedUser;
      return {
        ...state,
        usersList: updatedUsersList,
      };
    case actionTypes.USER_REMOVE:
      return {
        ...state,
        usersList: state.usersList.filter((user) => user.idUser !== payload.userid),
      };
    case actionTypes.USERS_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch } from 'react-redux';

import {
  makeStyles, Fade, Button, ClickAwayListener, Tooltip,
  Paper, Popper, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import RefreshIcon from '@mui/icons-material/Refresh';
// s
// import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
// import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';

// import { useAuth } from '../../../../hooks/useAuth';
import { fetchAll } from '../../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '250px',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
    borderRadius: '10px',
  },
  subHeader: {
    backgroundColor: theme.palette.grey.A400,
    color: theme.palette.common.white,
    padding: '5px 15px',
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
  menuButton: {
    [theme.breakpoints.down('sm')]: {
      minWidth: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '35px',
    },
  },
}));

function RefreshSection() {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  // const { user, logout } = useAuth();
  const dispatch = useDispatch();
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 4) {
      // handleLogout;
    }
  };

  const handleClick = () => {
    dispatch(fetchAll());
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Tooltip title="Refresh data">
      <Button
        className={classes.menuButton}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <RefreshIcon className={classes.menuIcon} />
      </Button>
    </Tooltip>
  );
}

export default RefreshSection;

/* eslint-disable no-restricted-syntax */
import {
  HTTP_HEADER_JSON,
} from './config';

export function getAllClients() {
  return fetch('/mtc/api/v1/clients', {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function getClientById(id) {
  return fetch(`/mtc/api/v1/clients/${id}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function createClient(clientData) {
  return fetch('/mtc/api/v1/clients', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(clientData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function updateClientById(id, updatedData) {
  return fetch(`/mtc/api/v1/clients/${id}`, {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(updatedData),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function deleteClientById(id) {
  return fetch(`/mtc/api/v1/clients/${id}`, {
    method: 'DELETE',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}
export function updateClientFieldById(id, clientField) {
  return fetch(`/mtc/api/v1/clients/${id}`, {
    method: 'PATCH',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(clientField),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}
// client dashboard
export function getClientDashboardData(queryParams) {
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return fetch(`mtc/api/v1/clients/dashboard?${queryString}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

// get client alerts
export function getClientDevicesAlerts(queryParams) {
  // &limit=1
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return fetch(`mtc/api/v1/clients/alerts?${queryString}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

export function getClientDeviceAlerts(queryParams) {
  // /mtc/api/v1/clients/alert?&nodeid=c4:4b:d1:00:7c:25&limit=10
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return fetch(`mtc/api/v1/clients/alert?${queryString}`, {
    method: 'GET',
    headers: HTTP_HEADER_JSON,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
}

/* eslint-disable no-case-declarations */
import * as actionTypes from '../actionTypes';

export const initialState = {
  isFetching: false,
  fetchTs: new Date().getTime(),
  clientNetworkData: null,
};

const clientNetworkVpnStatusReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.VPN_CLIENT_SERVER_VPN_STATUS_REQUEST_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.VPN_CLIENT_SERVER_VPN_STATUS_RECEIVE_LIST:
      return {
        ...state,
        isFetching: false,
        clientNetworkData: payload.clientNetworkData,
      };
    case actionTypes.VPN_CLIENT_SERVER_VPN_STATUS_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
};
export default clientNetworkVpnStatusReducer;

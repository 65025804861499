const host = '192.168.1.100';
const url = `http://${host}:11000`;
export const MICRO_SERVICES_URL = url;
export const HTTP_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
};
// 'Cache': 'no-cache'
export const HTTP_HEADER_FORM = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
};

export const HTTP_HEADER_WITHOUT_CONTENT_TYPE = {
  Accept: 'application/json',
};
export const HTTP_HEADER_JSON = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const MTC_API_V1_PATH = '/mtc/api/v1';

// ip = http://192.168.1.100:4000

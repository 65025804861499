import { HTTP_HEADER, HTTP_HEADER_JSON } from './config';

export function getUsersByClientId() {
  return fetch('/mtc/api/v1/users', {
    method: 'GET',
    headers: HTTP_HEADER,
  })
    .then((res) => {
      console.log(res);
      return res.json();
    })
    .catch((err) => { });// to be implemented
}

export function getUserById(id) {
  // get more info about user
  return fetch('/mtc/api/v1/users', {
    method: 'GET',
    headers: {
      method: 'GET',
      headers: HTTP_HEADER,
    },
    body: JSON.stringify({
      id,
    }),
  })
    .then((res) => res.json())
    .catch((err) => { });// to be implemented
}

export function createUser(userData) {
  return fetch('/mtc/api/v1/users', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(userData),
  })
    .then((res) => res.json())
    .catch((err) => { });// to be implemented
}

export function updateUserById(id, userData) {
  return fetch(`/mtc/api/v1/users/${id}`, {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(userData),
  })
    .then((res) => res.json())
    .catch((err) => { });// to be implemented
}

export function deleteUserById(id) {
  return fetch(`/mtc/api/v1/users/${id}`, {
    method: 'DELETE',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => { });// to be implemented
}

export function updateUserFieldById(id, userField) {
  return fetch(`/mtc/api/v1/users/${id}`, {
    method: 'PATCH',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(userField),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);// to be implemented
    });
}

import shortid from 'shortid';
import * as actionTypes from '../actionTypes';
import { idToParamMapBuilderFnc, idToObjMapBuilderFnc } from '../../helpers/functions';

export const initialState = {
  isFetching: false,
  vpnRoutingTable: [],
  fetchTs: new Date().getTime(),
};

const vpnReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.REQUEST_STATIC_NETWORK_LIST:
      return {
        ...state,
        isFetching: true,
        isFetchingVpnCert: true,
      };
    case actionTypes.RECEIVE_STATIC_NETWORK_LIST: {
      return {
        ...state,
        isFetching: false,
        isFetchingVpnIp: false,
        vpnRoutingTable: payload.data,
      };
    }

    default:
      return state;
  }
};

export default vpnReducer;

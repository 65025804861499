import * as actionTypes from '../actionTypes';
import config from '../../config';

export const initialState = {
  isOpen: window.location.hash.split('#/').pop(), // for active default menu
  locale: config.i18n,
  rtlLayout: config.rtlLayout,
  navType: config.theme,
  theme: config.theme,
  drawerOpen: config.drawerOpen,
};

const customizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case actionTypes.DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.drawerOpen,
      };
    case actionTypes.THEME_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case actionTypes.THEME_RTL:
      return {
        ...state,
        rtlLayout: action.rtlLayout,
      };
    case actionTypes.THEME_TYPE:
      return {
        ...state,
        navType: state.navType === 'dark' ? 'light' : 'dark',
      };
    default:
      return state;
  }
};

export default customizationReducer;
